import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Chip, Grid, Paper, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { setFilters } from "../../../features/Booking-Form/functions/form.functions";
import { setInputs } from "../../../features/Booking-Form/slices/booking.slice";
import { FilterDto } from "../../../features/Booking-Form/typings/inputs-filter";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import { MultiselectOption } from "../../../features/Reports/typings/reports.types";
import { MS } from "../../Dialog/form-dialog.component";
import InputsTeamExternal from "../../Dialog/partials/inputs-team-external.component";
import { enterpriseOrGrowth, filterExistingExternal, initSetTeam } from "../utils/booking.utils";
import { useRemoteFetchTeamBookingColleagues } from "../../../hooks/Remote/Booking/useRemoteFetchTeamBookingColleagues";

/**
 * @description Component that displays possible team members according to the previously selected restriction object.
 * @param props.nextStep as a function which goes to the next step in the booking process.
 * @param props.prevStep as a as a function which goes to the previous step in the booking process.
 * @param props.title as a string which declares the page title.
 * @param props.inputs as a BI which includes all the main inputs from the booking form component.
 * @param props.setInputs as a function which sets the inputs in the booking form component.
 * @version 0.1.0
 */

type Props = {
  title: string;
};

const FormTeamSelection: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  const { userInformation } = useSelector((state: RootState) => state.login);
  const { inputs } = useSelector((state: RootState) => state.booking);
  const { usersBookedFor } = useSelector((state: RootState) => state.booking.inputs);
  const preferredLocations = useSelector(
    (state: RootState) => state.login.settings.preferredLocations
  );

  const [options, setOptions] = useState<TeamMember[]>([]);
  const [team, setTeam] = useState<TeamMember[]>(
    usersBookedFor && usersBookedFor?.length >= 1 ? usersBookedFor : []
  );
  const [externals, setExternals] = useState<TeamMember[]>([]); // Externals created for this booking
  const [currentUser, setCurrentUser] = useState(0);

  const [modal, setModal] = useState<MS<TeamMember>>({ isOpen: false, data: undefined });
  const [shouldSave, setShouldSave] = useState(false);
  const [errorSelection, setErrorSelection] = useState(false);

  const [, setDeviceOptions] = useState<MultiselectOption[]>([]);
  const [, setDeviceCategoryOptions] = useState<MultiselectOption[]>([]);

  const { data: teamBookingColleagueData } = useRemoteFetchTeamBookingColleagues();

  const createExternal = () => {
    const ext = modal.data;
    if (ext) {
      setExternals(p => [...p, ext]);
      setTeam(p => [...p, ext]);
      setModal({ isOpen: false, data: null });
    } else {
      enqueueSnackbar(t("_allRightWarning"), { variant: "error" });
    }
  };

  const openModal = () => {
    setModal({
      isOpen: true,
      data: {
        userId: userInformation.sub,
        firstName: "",
        surname: "",
        email: "",
        isExternal: true,
        company: ""
      }
    });
  };

  /* istanbul ignore next */
  const handleTeamChange = (event: React.ChangeEvent<any>, values: TeamMember[]) => {
    event.persist();
    const teamMember = values.map(user => ({
      userBookedById: userInformation.sub,
      userId: user.userId,
      firstName: user.firstName,
      surname: user.surname,
      isExternal: user.isExternal || false,
      isUserBookedBy: user.userId === userInformation.sub,
      photoUrl: user.photoUrl,
      email: user.email,
      company: user.company
    }));

    if (!teamMember.length) {
      setErrorSelection(true);
      return setTimeout(() => setErrorSelection(false), 3000);
    }

    if (!teamMember[currentUser]) setCurrentUser(0);
    setTeam(teamMember);
  };

  useEffect(() => {
    // initial set
    initSetTeam(inputs, setTeam, userInformation);

    if (!teamBookingColleagueData) return;

    // in case, deduct user from team booking colleagues
    const teamBookingColleagues = teamBookingColleagueData.filter(
      data => data.userId !== userInformation.sub
    );
    // get team booking colleagues from connection and set them as options
    setOptions(teamBookingColleagues);

    // set externals if there are already created external members in the booking selection process
    const existingExternal = filterExistingExternal(inputs, userInformation, teamBookingColleagues);
    setExternals(existingExternal);
  }, [userInformation, teamBookingColleagueData]);

  useEffect(() => {
    dispatch(setInputs({ usersBookedFor: team }));
  }, [dispatch, inputs?.usersBookedFor?.length, team]);

  // Fetching filters
  useEffect(() => {
    if (preferredLocations?.length) {
      const filter: FilterDto = {
        type: "location",
        ids: preferredLocations?.map(l => Number(l))
      };
      setFilters(filter, setDeviceOptions, setDeviceCategoryOptions);
    }
  }, [preferredLocations]);

  useEffect(() => {
    if (shouldSave) {
      setShouldSave(false);
      createExternal();
    }
  }, [modal, shouldSave]);

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
        boxShadow: "none",
        gap: 2,
        backgroundImage: "none",
        backgroundColor: "unset",
        margin: "2% 0"
      }}
      data-testid="form-team-select-paper"
    >
      <Grid container sx={{ mb: 1.5 }}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>

      {enterpriseOrGrowth(userInformation.pricingModels) && (
        <Button data-testid="add-external-btn" onClick={openModal}>
          {t("Add External")}
        </Button>
      )}

      <Autocomplete
        data-testid="team-selection"
        multiple
        disableCloseOnSelect
        id="tags-outlined8"
        options={[
          ...options,
          ...externals,
          ...[
            {
              userBookedById: userInformation.sub,
              firstName: userInformation.firstName,
              surname: userInformation.surname,
              isExternal: false,
              isUserBookedBy: true,
              userId: userInformation.sub,
              photoUrl: userInformation.photoUrl,
              email: userInformation.email
            }
          ]
        ]}
        renderOption={(props, option) => {
          if (
            [
              ...options,
              ...externals,
              ...[
                {
                  userBookedById: userInformation.sub,
                  firstName: userInformation.firstName,
                  surname: userInformation.surname,
                  isExternal: false,
                  isUserBookedBy: true,
                  userId: userInformation.sub,
                  photoUrl: userInformation.photoUrl,
                  email: userInformation.email
                }
              ]
            ].length > 1
          ) {
            return (
              <li {...props} key={uuidv4()}>
                <div data-testid="team-selection-option">
                  {option.firstName + " " + option.surname}
                </div>
              </li>
            );
          }
          // when no options are existed
          return (
            <div key={uuidv4()}>
              <Button
                style={{ width: "100%" }}
                onClick={e => {
                  e.stopPropagation();
                  history.push("/connections");
                }}
                color={"primary"}
              >
                {t("Add Colleague")}
              </Button>
            </div>
          );
        }}
        isOptionEqualToValue={(option, value) =>
          // double-check because the external members share userId with logged-in user id
          option.firstName === value.firstName && value.email === option.email
        }
        style={{ width: "100%" }}
        value={team}
        onChange={handleTeamChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            placeholder={t("Choose your colleagues")}
            error={errorSelection}
            helperText={errorSelection ? t("At least one is required to start booking") : ""}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...rest } = getTagProps({ index });
            return (
              <Chip
                {...getTagProps({ index })}
                {...rest}
                label={option.firstName + " " + option.surname}
                key={key}
              />
            );
          })
        }
        getOptionLabel={option => option.firstName + " " + option.surname}
      />

      <InputsTeamExternal
        modalState={modal}
        onCancel={() => setModal({ isOpen: false, data: null })}
        onSubmit={o => {
          setModal(prevState => ({ isOpen: false, data: { ...prevState.data!, ...o } }));
          setShouldSave(true);
        }}
      />
    </Paper>
  );
};

export default FormTeamSelection;
