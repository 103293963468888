import { Dispatch, SetStateAction } from "react";
import { GroupFilterSelected, GroupFilterType } from "../types/GroupFilters.type";

export const handleFilterChange = (
  event: any,
  prop: "Check" | "Qty",
  type: GroupFilterType,
  filterItems: GroupFilterSelected[],
  setFilterItems: Dispatch<SetStateAction<GroupFilterSelected[]>>
) => {
  const { id, name, checked, value } = event.target;
  const numId = Number(id);

  if (prop === "Check") {
    if (checked) setFilterItems(prev => [...prev, { id: numId, name, checked, type }]);
    else setFilterItems(filterItems.filter(curr => curr.id !== numId && curr.name !== name));
  } else {
    // when input is Quantity
    /* istanbul ignore next */
    setFilterItems(prev => {
      const upd = prev;
      const i = upd.findIndex(p => p.id === numId && p.name === name);
      if (i >= 0) upd[i].quantity = Number(value);
      return upd;
    });
  }
};

export function initFilterSubStyles(
  type: GroupFilterType,
  showDetailGroupFilter: boolean,
  toggleMoreItems: boolean,
  isMobile: boolean
) {
  const titleTypoFontSizeMobile = "1em";
  const filterBodyPaddingLeftMobile = 2;
  const controlLabelItemForEquipCate = type === GroupFilterType.EQUIPMENTCATEGORY ? 10.5 : 12;

  const filterSubStyles = {
    expandIconTransform: toggleMoreItems ? "rotate(0deg)" : "rotate(180deg)",
    titleTypoFontSize: isMobile ? titleTypoFontSizeMobile : "1.1em",
    filterBodyPaddingLeft: isMobile ? filterBodyPaddingLeftMobile : 4,
    descItemXsMobile: showDetailGroupFilter ? 12 : 0,
    dividerWidth: isMobile ? "90%" : "98%",
    controlLabelMinWidth:
      type === GroupFilterType.EQUIPMENTCATEGORY ? "-webkit-fill-available" : "100%", // "60%" : "100%"
    controlLabelItemXs: isMobile ? 11 : controlLabelItemForEquipCate
  };

  return filterSubStyles;
}

export function initFilterSubItemStyles(
  type: GroupFilterType,
  showDetailGroupFilter: boolean,
  isMobile: boolean
) {
  const descItemXsForEquipCate = showDetailGroupFilter ? 8 : 0;
  const entryNameXsForEquipCate = type === GroupFilterType.EQUIPMENTCATEGORY ? 8 : 12;
  const entryNameXsForEquipCateDetail = showDetailGroupFilter ? 4 : 8;

  const filterSubItemStyles = {
    entryNameXs: isMobile ? entryNameXsForEquipCate : entryNameXsForEquipCateDetail,
    quantityFieldWidth: isMobile ? "55px" : "70px",
    quantityFieldXs: isMobile ? 1 : 1.5, // 1 : false,
    descItemXs: type === GroupFilterType.EQUIPMENTCATEGORY ? descItemXsForEquipCate : 8,
    descItemPaddingLeft: type === GroupFilterType.EQUIPMENTCATEGORY ? 1.5 : 0
  };

  return filterSubItemStyles;
}
