import React, { forwardRef } from "react";
import { LocationInventory } from "../../../features/FloorManager/typings/location-inventory";
import { useTranslation } from "react-i18next";
import { Box, Button, Card, CardContent, CardMedia, Typography } from "@mui/material";

type P = {
  location: LocationInventory;
  onClickEnable?: () => void;
};

export const LocationNonPrefferedCard: React.FC<P> = forwardRef<HTMLDivElement, P>(
  ({ location, onClickEnable }, ref) => {
    const { t } = useTranslation();

    return (
      <Card
        sx={{
          display: "flex",
          width: "95vw",
          minHeight: 193,
          maxWidth: 400,
          margin: "10px 20px 20px 0"
        }}
        ref={ref}
      >
        <CardMedia
          sx={{ opacity: 0.4, minWidth: 160 }}
          image={location.picturePath ?? "https://picsum.photos/190.webp"}
          title={location.name}
        />
        <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 345 }}>
          <CardContent sx={{ flex: "0 0 auto" }}>
            <Typography sx={{ opacity: 0.4 }} component="h5" variant="h5">
              {location.name}
            </Typography>
            <Typography sx={{ opacity: 0.4 }} variant="subtitle1" color="textSecondary">
              {location.address1}
            </Typography>
          </CardContent>

          {/* add location button */}
          {onClickEnable && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 2,
                paddingBottom: 1,
                height: "100%"
              }}
            >
              <Button
                data-testid={"button-add-location"}
                onClick={onClickEnable}
                variant={"outlined"}
              >
                {t("AddLocation")}
              </Button>
            </Box>
          )}
        </Box>
      </Card>
    );
  }
);
