import { useEffect, useMemo, useState } from "react";
import { Button, Grid, InputAdornment, useTheme } from "@mui/material";
import {
  MRT_BottomToolbar,
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import { useTranslation } from "react-i18next";
import {
  ServiceBookableEquipmentRow,
  SingleBookableEquipmentDto
} from "./typings/ServiceBookableEquipment.types";
import { IEquipmentInventory } from "../../../../../Domain/Types/FloorPlan/Equipment.type";
import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { commonLocalization } from "../../../../../../../functions/tableLocalization";
import { useRemoteFloorPlanService } from "../../../../../../../hooks/Remote/FloorPlan/useRemoteFloorPlanService";
import {
  renderEquipmentImage,
  renderEquipmentOrderleadTime,
  renderEquipmentSetupTime,
  updateBookableEquipTime
} from "./BookableEquipmentTable.partial";

type Props = {
  zoneSchedule: IZoneSchedule;
  bookableEquipment: IEquipmentInventory[];
  onChangeConfService: (
    id: number,
    service: { bookableEquipments: SingleBookableEquipmentDto[] }
  ) => void;
  onClose: () => void;
};

/**
 * A tables that displays bookable equipments
 */
export default function ServiceBookableEquipmentTable({
  zoneSchedule,
  bookableEquipment,
  onChangeConfService,
  onClose
}: Props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tablePaperThemeColor = theme.palette.background.paper;

  const [data, setData] = useState<ServiceBookableEquipmentRow[]>();
  const [editedData, setEditedData] = useState<ServiceBookableEquipmentRow[]>();

  const { isLoading: mutateServiceIsLoading } = useRemoteFloorPlanService();

  const column = useMemo<MRT_ColumnDef<ServiceBookableEquipmentRow>[]>(
    () => [
      {
        accessorKey: "imageUrl",
        header: t("Image"),
        enableEditing: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => renderEquipmentImage(cell.row.original, 100),
        size: 150
      },
      {
        accessorKey: "name",
        header: t("Name"),
        size: 250,
        enableEditing: false
      },
      {
        header: t("Category"),
        accessorKey: "equipmentCategory.name",
        size: 220,
        enableEditing: false
      },
      {
        header: t("Asset Id"),
        accessorKey: "assetRefId",
        size: 200,
        enableEditing: false
      },
      {
        header: t("Order Lead Time"),
        accessorKey: "orderLeadTime",
        size: 200,
        enableEditing: true,
        muiTableBodyCellProps: {
          sx: { textAlign: "end", backgroundColor: tablePaperThemeColor }
        },
        Header: renderEquipmentOrderleadTime(),
        muiEditTextFieldProps: ({ row }) => {
          return {
            type: "number",
            onChange: event => {
              const val = Number(event.target.value).toFixed(1);

              updateBookableEquipTime(
                row,
                { orderLeadTime: Number(val) },
                editedData,
                setEditedData
              );
            },
            inputProps: { step: 1, min: 0.0 },
            InputProps: {
              endAdornment: (
                <InputAdornment sx={{ pl: 2 }} position="start">
                  {t("Hour")}
                </InputAdornment>
              )
            },
            sx: { width: "150px" }
          };
        }
      },
      {
        header: t("Setup Time"),
        accessorKey: "setupTime",
        size: 200,
        enableEditing: true,
        muiTableBodyCellProps: {
          sx: { textAlign: "end", backgroundColor: tablePaperThemeColor }
        },
        Header: renderEquipmentSetupTime(),
        muiEditTextFieldProps: ({ row }) => {
          return {
            type: "number",
            onChange: event =>
              updateBookableEquipTime(
                row,
                { setupTime: Number(event.target.value) },
                editedData,
                setEditedData
              ),
            inputProps: { step: 5, min: 0 },
            InputProps: {
              endAdornment: (
                <InputAdornment sx={{ pl: 2 }} position="start">
                  {"mins"}
                </InputAdornment>
              )
            },
            sx: { width: "150px" }
          };
        }
      }
    ],
    [editedData, i18n.language]
  );

  useEffect(() => {
    const modified = bookableEquipment.map(el => ({
      ...el,
      orderLeadTime: 1.0, // hour unit // el.orderLeadTime
      setupTime: 15 // minute unit // el.setupTime
    }));

    setData(modified);
    setEditedData(modified);
  }, [bookableEquipment]);

  const table = useMaterialReactTable({
    columns: column ?? [],
    data: data ?? [],
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enableRowSelection: true,
    editDisplayMode: "table",
    enableEditing: true,
    getRowId: row => row.id?.toString(),
    state: { isSaving: mutateServiceIsLoading },
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: { pageSize: 5, pageIndex: 0 }
    },
    positionToolbarAlertBanner: "none",
    muiSearchTextFieldProps: { size: "small", variant: "outlined" },
    muiPaginationProps: { rowsPerPageOptions: [5, 10, 15], variant: "outlined" },
    paginationDisplayMode: "pages",
    localization: {
      ...commonLocalization(t),
      noRecordsToDisplay: t("_editable_list_noRecords", { item: t("_noBookableEquipments") })
    },
    muiTablePaperProps: {
      sx: { backgroundColor: tablePaperThemeColor, boxShadow: "none", width: "inherit" }
    },
    muiTableContainerProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiTableBodyCellProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiTableHeadCellProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiTopToolbarProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiBottomToolbarProps: { sx: { backgroundColor: tablePaperThemeColor } },
    renderBottomToolbar: ({ table }) => {
      return (
        <Grid>
          <Grid item>
            <MRT_BottomToolbar table={table} />
          </Grid>
          <Grid item textAlign={"end"} sx={{ mt: 3 }}>
            <Button data-testid="cancel-btn" onClick={onClose} color="secondary" sx={{ mr: 2 }}>
              {t("Cancel")}
            </Button>
            <Button
              data-testid="save-btn"
              onClick={() => {
                if (!editedData) return;
                /** mutateService({zoneScheduleId: zoneSchedule.id,bookableEquipments: editedData}); */
                onChangeConfService(zoneSchedule.id, { bookableEquipments: editedData });
                onClose();
              }}
            >
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      );
    }
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
}
