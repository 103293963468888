import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

export function ShowMoreDescription({
  description,
  index,
  length,
  spaceStyleY
}: {
  description: string | null;
  index: number;
  length: number;
  spaceStyleY: number;
}) {
  return (
    <>
      <Grid item pl={2} py={spaceStyleY}>
        {description}
      </Grid>
      {index !== length - 1 && <Divider sx={{ my: spaceStyleY }} />}
    </>
  );
}
