export enum BrowserType {
  MSEDGE = "Microsoft Edge",
  CHROME = "Google Chrome",
  FIREFOX = "Mozilla Firefox",
  SAFARI = "Safari",
  IEXPLORER = "Internet Explorer",
  UNKONWN = "Unknown Browser"
}

/**
 * function to detech what browser the user is using
 * @returns name of browser what user uses
 */
export function getBrowser(userAgent: string): BrowserType {
  if (userAgent.indexOf("Chrome") > -1) {
    if (userAgent.indexOf("Edg") > -1) {
      return BrowserType.MSEDGE; // "Microsoft Edge"; // Edge uses Chromium engine
    }
    return BrowserType.CHROME; // "Google Chrome";
  }
  if (userAgent.indexOf("Firefox") > -1) {
    return BrowserType.FIREFOX; // "Mozilla Firefox";
  }
  if (userAgent.indexOf("Safari") > -1) {
    if (userAgent.indexOf("Chrome") === -1) {
      return BrowserType.SAFARI; // "Safari"; // Safari's user agent does not include 'Chrome'
    }
  }
  if (userAgent.indexOf("Edge") > -1) {
    return BrowserType.MSEDGE; // "Microsoft Edge";
  }
  if (userAgent.indexOf("Trident") > -1) {
    return BrowserType.IEXPLORER; // "Internet Explorer";
  }
  return BrowserType.UNKONWN; // "Unknown Browser";
}
