import React, { useState } from "react";
import { Box, Divider, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaAdministration from "./meta-administration.component";
import Departments from "./departments.component";
import UserManagement from "./UserManagement/user-management.component";
import { a11yProps } from "../TabPanel/TabPanel.partial";
import { TabPanel } from "../TabPanel/TabPanel.component";
import { CustomColoredTabs } from "../../components/CustomColoredTabs/CustomColoredTabs.component";
import { CustomColoredTab } from "../../components/CustomColoredTabs/CustomColoredTab.component";

export const Organization = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [valueState, setValueState] = useState(0);

  const handleChange = (event: React.SyntheticEvent<{}>, newValue: number) =>
    setValueState(newValue);

  return (
    <Box sx={{ "& > *": { margin: theme.spacing(1) } }} data-testid="organization-tabs-parent">
      <CustomColoredTabs value={valueState} onChange={handleChange} theme={theme}>
        <CustomColoredTab
          index={0}
          theme={theme}
          data-testid={"tab1"}
          label={t("Company")}
          {...a11yProps(0)}
        />
        <CustomColoredTab
          index={1}
          theme={theme}
          data-testid={"tab2"}
          label={t("Departments")}
          {...a11yProps(1)}
        />
        <CustomColoredTab
          index={2}
          theme={theme}
          data-testid={"tab3"}
          label={t("User")}
          {...a11yProps(2)}
        />
      </CustomColoredTabs>

      <Divider style={{ width: "100%" }} variant={"fullWidth"} />
      <TabPanel value={valueState} index={0}>
        <MetaAdministration />
      </TabPanel>
      <TabPanel value={valueState} index={1}>
        <Departments />
      </TabPanel>
      <TabPanel value={valueState} index={2}>
        <UserManagement />
      </TabPanel>
    </Box>
  );
};
