import { Datepicker, locale } from "@mobiscroll/react";
import { Button, Card, Grid, IconButton, styled, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import useTimeFormat from "../../../../../hooks/useTimeFormat/useTimeFormat";
import { handleStartDateChange } from "../../menu-timeframes.functions";
import { initialStartDate } from "../NewTimeframeCreator";
import { FloorInventory } from "../../../typings/floor-inventory.entity";
import { Timeframe } from "../../../typings/shared/timeframe";

export const StyledTimeframeNewItemCard = styled(Card, {
  name: "StyledTimeframeDetailCard"
})(() => ({
  width: 420,
  height: 185,
  padding: "16px",
  margin: "10px 10px 16px 4px"
}));

export function NewTimeframeItem({
  selectedFloor,
  floorTimeframes,
  startDate,
  setNewTimeframe,
  setStartDate,
  setShowTimeframeDetail,
  clickNextCreate
}: {
  selectedFloor: FloorInventory;
  floorTimeframes: Timeframe[];
  startDate: DateTime | null;
  setNewTimeframe: (tf: boolean) => void;
  setStartDate: React.Dispatch<React.SetStateAction<DateTime | null>>;
  setShowTimeframeDetail: (d: string | null) => void;
  clickNextCreate: (startDay: string) => void;
}) {
  const { t, i18n } = useTranslation();
  const { dateFormat } = useTimeFormat();

  const clearTimeframe = () => {
    setStartDate(null);
    setNewTimeframe(false);
    setShowTimeframeDetail(DateTime.fromISO(floorTimeframes[0].start).toFormat(dateFormat));
  };

  return (
    <>
      {/* floor section */}
      <StyledTimeframeNewItemCard>
        <Grid container sx={{}} data-testid="new-timeframe-floor-container">
          {/* header of the selected floor */}
          <Grid item container sx={{ height: "80%", justifyContent: "space-between" }}>
            <Grid item>
              <Typography variant={"h6"}>{selectedFloor.name}</Typography>
              <Typography variant={"subtitle1"}>{selectedFloor.level}</Typography>
            </Grid>

            {/* clear new timeframe button */}
            <Grid item>
              <IconButton onClick={clearTimeframe} data-testid="clear-btn">
                <Close />
              </IconButton>
            </Grid>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Datepicker
              data-testid={"new-timeframe-input"}
              min={initialStartDate(floorTimeframes, dateFormat)}
              id="date-picker-inline"
              label={t("Start date")}
              value={startDate?.toFormat(dateFormat) ?? ""}
              onChange={ev => handleStartDateChange(ev.value as Date, setStartDate)}
              dateFormat={dateFormat.toUpperCase()}
              locale={locale[i18n.language]}
            />

            <Button
              sx={{ mt: 2 }}
              data-testid="next-btn"
              disabled={!startDate || startDate.valueOf() < new Date().valueOf()}
              onClick={() => {
                /**
                 * fetch new date when editing a time
                 * when it cannot be null, if it is, button is disabled
                 */
                const startDay = (startDate as DateTime).toFormat("yyyy-MM-dd");
                clickNextCreate(startDay);
              }}
            >
              {t("Create")}
            </Button>
          </Grid>
        </Grid>
      </StyledTimeframeNewItemCard>
    </>
  );
}
