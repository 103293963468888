import { Dispatch, SetStateAction } from "react";
import { FormControl, Paper, Typography, useTheme } from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { Datepicker, locale } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import AlertDialog from "../../../Dialog/alert-dialog-button.component";
import { RFi } from "../../../../features/Reports/typings/reports.types";
import { MetaData } from "../../../../features/Login/typings/login.types";
import { setHourlyWorkplaceCost } from "../../../../utils/axios-requests";

interface Props {
  financeReport: RFi;
  metaData: MetaData;
  setFinanceReport: Dispatch<SetStateAction<RFi>>;
}

const round = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export function FinanceReportSimulationResult({
  financeReport,
  metaData,
  setFinanceReport
}: Props) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const DataPie = [
    {
      name: t("Simulated Volume"),
      DeskBookingCost: round(financeReport.costs!),
      ZoneBookingCost: round(financeReport.companyUnitsVacancyCosts!),
      Difference: round(financeReport.facilityVacancyCosts!)
    }
  ];

  const handleWorkplaceCost = () => {
    setHourlyWorkplaceCost({
      periodLength: Number(financeReport.periodLength),
      budget: Number(financeReport.budget),
      workingHoursPerMonth: Number(financeReport.workingHoursPerMonth),
      vacancyFactor: Number(financeReport.vacancyFactor),
      incentiveFactor: Number(financeReport.incentiveFactor),
      id: Number(financeReport.selectedInitialLocation),
      start: financeReport.validFrom ? financeReport.validFrom.toFormat("yyyy-MM-dd") : "",
      defaultDeskCost: Number(financeReport.costs)
    })
      .then(() => enqueueSnackbar("Successfully set new workplace cost.", { variant: "success" }))
      .catch(() => enqueueSnackbar("Something went wrong", { variant: "error" }));
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "nowrap",
        padding: 2,
        marginRight: 1,
        marginTop: 1
      }}
    >
      <Typography align={"center"} variant={"h6"}>
        {t("Simulation")}
      </Typography>
      {financeReport.price !== undefined && (
        <>
          <BarChart
            width={300}
            height={335}
            data={DataPie}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis label={{ value: t("Simulated Volume"), fill: "white" }} dataKey="name" />
            <YAxis />
            <Tooltip
              contentStyle={{ background: theme.palette.background.paper }}
              wrapperStyle={{ background: theme.palette.background.paper }}
            />

            <Legend />
            <Bar
              name={`${t("Workplace Booking Cost")} (${metaData.currency})`}
              dataKey="DeskBookingCost"
              stackId="a"
              fill={theme.palette.success.main}
            />
            <Bar
              name={`${t("Zone Booking Cost")} (${metaData.currency})`}
              dataKey="ZoneBookingCost"
              stackId="a"
              fill="#c3853e"
            />
            <ReferenceLine y={round(financeReport.totalCost!)} stroke="red" strokeWidth="3" />
            <Bar
              name={`${t("Difference")} (${metaData.currency})`}
              dataKey="Difference"
              stackId="a"
              fill="#d32f2f"
            />
          </BarChart>
          <FormControl
            sx={{
              minWidth: 150,
              maxWidth: 200
            }}
          >
            <Datepicker
              label={t("Valid from")}
              min={DateTime.now().plus({ days: 1 })}
              value={financeReport.validFrom || null}
              onChange={ev => {
                const date = ev.value && DateTime.fromJSDate(ev.value as Date);
                date &&
                  setFinanceReport(prevState => ({
                    ...prevState,
                    validFrom: date
                  }));
              }}
              locale={locale[i18n.language]}
            />
            {financeReport.price && <Typography variant={"body2"} />}
          </FormControl>

          <AlertDialog
            data-testid="Desk-Cost-Change"
            contentTitle={t("Desk Cost Change")}
            content={t("_deskCostChangeAlert", {
              initialLocation: financeReport.selectedInitialLocation,
              price: round(financeReport.price!),
              currency: metaData.currency,
              validFrom: financeReport.validFrom ? financeReport.validFrom.toFormat("LL") : ""
            })}
            labelAccept={t("Confirm")}
            mode={false}
            labelOpen={
              t("Set new Workplace Cost") +
              ": " +
              round(Number(financeReport?.price)) +
              metaData.currency
            }
            disabled={
              !financeReport.incentiveFactor! ||
              !financeReport.vacancyFactor! ||
              !financeReport.budget! ||
              !financeReport.workingHoursPerMonth! ||
              !financeReport.periodLength! ||
              !financeReport.validFrom
            }
            closeFn={handleWorkplaceCost}
          />
        </>
      )}
    </Paper>
  );
}
