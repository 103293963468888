import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateCompanyDialog } from "./CreateCompanyDialog/CreateCompanyDialog";
import { v4 as uuid } from "uuid";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";

export type currency = {
  symbol: string;
  name: string;
  code: string;
};

type P = {
  enableNext?: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
};

/**
 * @description Component that renders the meta data administration.
 * @version 0.1.0
 */
const MetaAdministrationDialog: React.FC<P> = ({ enableNext, onConfirm }) => {
  const [createCompanyModalOpen, setCreateCompanyModalOpen] = useState(false);
  const [key, setKey] = useState(uuid());
  const { t } = useTranslation();
  const {
    userRoles: { isTenantAdmin }
  } = useSelector((state: RootState) => state.login);

  // this key is required to reset the dialog once its closedq
  useEffect(() => {
    setKey(uuid());
  }, [createCompanyModalOpen]);

  return (
    <>
      {isTenantAdmin && (
        <Button
          variant={"contained"}
          data-testid={"create-company-btn"}
          color="primary"
          onClick={() => setCreateCompanyModalOpen(true)}
        >
          <Add />
          {t("Create company")}
        </Button>
      )}
      <CreateCompanyDialog
        key={key}
        onConfirm={onConfirm}
        enableNext={enableNext}
        createCompanyModalOpen={createCompanyModalOpen}
        setCreateCompanyModalOpen={setCreateCompanyModalOpen}
      />
    </>
  );
};

export default MetaAdministrationDialog;
