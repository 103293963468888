import { ReactNode, Dispatch, SetStateAction } from "react";
import { useTheme } from "@mui/material";
import { CustomColoredTabs } from "../../CustomColoredTabs/CustomColoredTabs.component";

type Props = {
  children: ReactNode;
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
  ["data-testid"]: string;
};

export function ReportTabs({
  children,
  selectedTab,
  setSelectedTab,
  ["data-testid"]: dataTestId
}: Props) {
  const theme = useTheme();

  return (
    <CustomColoredTabs
      data-testid={dataTestId}
      value={selectedTab}
      onChange={(event, newValue: number) => setSelectedTab(newValue)}
      theme={theme}
      sx={{ mb: 4 }}
    >
      {children}
    </CustomColoredTabs>
  );
}
