import { styled, Tab, Theme } from "@mui/material";
import { a11yProps } from "../../features/TabPanel/TabPanel.partial";

interface StyledTabProps {
  index: number;
  theme: Theme;
  label: string;
  "data-testid"?: string;
}

export const CustomColoredTab = styled((props: StyledTabProps) => (
  <Tab
    disableRipple
    data-testid={props["data-testid"] ?? props.label}
    {...props}
    {...a11yProps(props.index)}
  />
))(({ theme }) => ({
  "&.Mui-selected": {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.getContrastText(theme.palette.primary.main)
        : theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold
  }
}));
