import {
  Button,
  Dialog,
  DialogContent,
  Fade,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  AccessTime,
  Apartment,
  Assistant,
  Devices,
  IntegrationInstructions
} from "@mui/icons-material";
import { Datepicker, locale } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { AutomatedBookingRoutine } from "../../../features/Booking-Form/partials/AutomatedBookingRoutinePartial/automated-booking-routine.partial";
import { LocationInventory } from "../../../features/FloorManager/typings/location-inventory";
import { setRequireSettings, setSettings } from "../../../features/Login/slices/login.slice";
import { PricingModel } from "../../../features/Login/typings/login.types";
import { getLocationsWithoutMobileWorking } from "../../../utils/axios-requests";
import LocationCombo from "../../Pickers/location-combo.component";
import {
  automatedBookingTextToggle,
  homeOfficePolicyText,
  WorkingDays
} from "../utils/booking.utils";
import useTimeFormat from "../../../hooks/useTimeFormat/useTimeFormat";
import { RootState } from "../../../app/rootReducer";
import { useHomeOffice } from "./Hooks/useHomeOffice";
import PreferredCategoryCombo from "../../Pickers/CategorySelect/preferred-category-select.component";
import { useRemoteSetUserSettings } from "../../../hooks/Remote/User/useRemoteSetUserSettings";
import CalendarSyncIntegrationBooking from "../../Settings/calendarSync-integration-booking.component";

export default function BookingSettingsView() {
  const {
    userInformation: { automatedBookingActivated, pricingModels, sub, company },
    settings
  } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const matches = useMediaQuery("(max-width:1000px)");
  const flexWrap = matches ? "wrap" : "nowrap";

  const automatedBookingValue = automatedBookingActivated ? "auto" : "";

  const [automatedOpen, setAutomatedOpen] = useState(false);
  const [calendarSyncOpen, setCalendarSyncOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [locations, setLocations] = useState<LocationInventory[]>([]);
  const [toggle, setToggle] = React.useState(automatedBookingValue);

  const { timeFormat } = useTimeFormat();
  const { homeOfficeRatio, mobileWorkingWeekdays } = useHomeOffice(sub, pricingModels);

  const { mutateAsync: updateUserSettings } = useRemoteSetUserSettings();

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newToggle: string) => {
    setToggle(newToggle);
  };

  const postSettings = () => {
    updateUserSettings(settings)
      .then(() => {
        setOpen(false);
        setLocationOpen(false);
        dispatch(setRequireSettings({ requireSettings: false }));
        enqueueSnackbar(t("Success"), { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(t("There has been a fatal error."), { variant: "error" });
      });
  };

  useEffect(() => {
    !settings.preferredLocations?.length && setLocationOpen(true);
  }, [settings.preferredLocations]);

  useEffect(() => {
    getLocationsWithoutMobileWorking(false, true, true)
      .then(res => {
        setLocations(res.data);
      })
      .catch(() => {
        enqueueSnackbar(t("There has been a fatal error."), { variant: "error" });
      });
  }, []);

  const workingDays = WorkingDays(mobileWorkingWeekdays);

  const mobileWorkingAllowedText = (v: boolean) =>
    v ? (t("_bookingAllowed") as string) : (t("_bookingNotAllowed") as string);

  const mobileWorkingDaysButtons =
    mobileWorkingWeekdays &&
    Object.entries(mobileWorkingWeekdays).map(([k, v]) => {
      if (k) {
        return (
          <Tooltip
            key={k}
            enterTouchDelay={0}
            title={<Typography>{mobileWorkingAllowedText(v)}</Typography>}
            aria-label={mobileWorkingAllowedText(v)}
          >
            <ToggleButton
              disableRipple
              style={{ cursor: "inherit" }}
              value="auto"
              selected={!!v} // because in some cases the value is string
              aria-label="left aligned"
            >
              {t(k)}
            </ToggleButton>
          </Tooltip>
        );
      }
    });

  const defaultStartTime = useMemo(
    () => DateTime.fromISO(settings.standardStartTime).toFormat(timeFormat),
    [timeFormat, settings.standardStartTime]
  );
  const defaultEndTime = useMemo(
    () => DateTime.fromISO(settings.standardEndTime).toFormat(timeFormat),
    [timeFormat, settings.standardEndTime]
  );

  return (
    <>
      <Fade in={true}>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            border: `1px solid black`,
            flexWrap: "wrap",
            minWidth: 200
          }}
        >
          <ToggleButtonGroup
            size="small"
            sx={{
              display: "flex",
              flexWrap: flexWrap,
              justifyContent: "center",
              alignItems: "stretch",
              "& button": {
                margin: 0.4,
                border: "none",
                "&:not(:first-of-type)": {
                  borderRadius: 1
                },
                "&:first-of-type": {
                  borderRadius: 1
                }
              }
            }}
            value={toggle}
            exclusive
            onChange={handleToggle}
            aria-label="text alignment"
          >
            {/* default bookable Locations */}
            <Tooltip
              enterTouchDelay={0}
              title={<Typography>{t("_defaultLocations") as string}</Typography>}
              aria-label={"_defaultLocations"}
            >
              <ToggleButton
                onClick={() => setLocationOpen(true)}
                value="auto"
                selected={automatedBookingActivated}
                aria-label="left aligned"
                data-testid="bookable-locations"
              >
                <Apartment style={{ marginRight: 5 }} />
                {locations
                  .filter(location => settings?.preferredLocations?.includes(String(location.id)))
                  .map(location => location.name)
                  .join(", ")}
              </ToggleButton>
            </Tooltip>

            <Grid sx={{ display: "contents" }}>
              {/* standard bookingtime */}
              <Tooltip
                enterTouchDelay={0}
                title={<Typography>{t("_standardBookingTime") as string}</Typography>}
                aria-label={"_standardBookingTime"}
              >
                <ToggleButton
                  onClick={() => setOpen(true)}
                  data-testid={"open-standard-booking-times-dialog"}
                  value="auto"
                  selected={automatedBookingActivated}
                  aria-label="left aligned"
                  sx={{ minWidth: "10%" }}
                >
                  <AccessTime style={{ marginRight: 5 }} />
                  {defaultStartTime} - {defaultEndTime}
                </ToggleButton>
              </Tooltip>

              {/* automated-booking-activate */}
              {company.meta.bookingAssistenceActive && (
                <Tooltip
                  enterTouchDelay={0}
                  title={
                    <Typography>{automatedBookingTextToggle(automatedBookingActivated)}</Typography>
                  }
                  aria-label={automatedBookingTextToggle(automatedBookingActivated)}
                >
                  <ToggleButton
                    onClick={() => setAutomatedOpen(true)}
                    value="auto"
                    selected={automatedBookingActivated}
                    aria-label="left aligned"
                    data-testid={"open-automated-booking-activate-dialog"}
                  >
                    <Assistant />
                  </ToggleButton>
                </Tooltip>
              )}

              {/* calendar sync and integrations */}
              {company.meta.calendarSyncPossible && (
                <Tooltip
                  enterTouchDelay={0}
                  title={<Typography>{t("_calendarSyncAndIntegrationsTitle")}</Typography>}
                  aria-label={"_calendarSyncAndIntegrationsTitle"}
                >
                  <ToggleButton
                    onClick={() => setCalendarSyncOpen(true)}
                    value="auto"
                    selected={company.meta.calendarSyncPossible}
                    aria-label="left aligned"
                    data-testid={"open-calendarSync-dialog"}
                  >
                    <IntegrationInstructions />
                  </ToggleButton>
                </Tooltip>
              )}
            </Grid>

            {/* hoursLeft HomeOffice */}
            {pricingModels.includes(PricingModel.ENTERPRISE) &&
              homeOfficeRatio.minMobileWorkingBookingHours +
                homeOfficeRatio.mobileWorkingBookingHours >
                0 && (
                <Tooltip
                  enterTouchDelay={0}
                  title={<Typography>{t("_hoursLeftHomeOffice") as string}</Typography>}
                  aria-label={"_hoursLeftHomeOffice"}
                >
                  <Grid
                    sx={{ width: "100%" }}
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography style={{ marginLeft: 5, marginRight: 5 }}>
                      {homeOfficePolicyText(homeOfficeRatio)}
                    </Typography>
                    <Devices style={{ marginRight: 10 }} />
                  </Grid>
                </Tooltip>
              )}

            {/* working days button group */}
            <Grid sx={{ display: "contents", alignSelf: "center" }}>
              {workingDays && mobileWorkingWeekdays && mobileWorkingDaysButtons}
            </Grid>
          </ToggleButtonGroup>
        </Paper>
      </Fade>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
        PaperProps={{ sx: { backgroundImage: "none" } }}
      >
        <DialogContent data-testid="default-bookingTime-picker-dialogContent" sx={{ padding: 2 }}>
          <Grid
            container
            data-testid="default-bookingTime-picker-container"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
            display={isMobile ? "inline-block" : "flex"}
          >
            <Grid
              item
              xs={4}
              data-testid="default-bookingTime-start-picker"
              sx={{ maxWidth: "fit-content" }}
            >
              <Datepicker
                controls={["time"]}
                timeFormat={timeFormat}
                data-testid="default-start-picker"
                onChange={ev => {
                  const val = DateTime.fromJSDate(ev.value as Date);
                  val &&
                    dispatch(
                      setSettings({
                        settings: {
                          ...settings,
                          standardStartTime: val.toFormat("HH:mm")
                        }
                      })
                    );
                }}
                value={DateTime.fromISO(
                  `${DateTime.now().toFormat("yyyy-MM-dd")}T${settings.standardStartTime}`
                ).toFormat("HH:mm")}
                stepMinute={5}
                label={t("Standard Booking Start")}
                locale={locale[i18n.language]}
              />
            </Grid>
            <Grid
              item
              xs={4}
              data-testid="default-bookingTime-end-picker"
              sx={{ maxWidth: "fit-content" }}
            >
              <Datepicker
                controls={["time"]}
                timeFormat={timeFormat}
                data-testid="default-end-picker"
                onChange={ev => {
                  const val = DateTime.fromJSDate(ev.value as Date);
                  val &&
                    dispatch(
                      setSettings({
                        settings: {
                          ...settings,
                          standardEndTime: val.toFormat("HH:mm")
                        }
                      })
                    );
                }}
                value={DateTime.fromISO(
                  `${DateTime.now().toFormat("yyyy-MM-dd")}T${settings.standardEndTime}`
                ).toFormat("HH:mm")}
                stepMinute={5}
                label={t("Standard Booking End")}
                locale={locale[i18n.language]}
              />
            </Grid>
            <Grid
              item
              xs={4}
              data-testid="default-bookingTime-confirm"
              sx={{ textAlign: "center", placeSelf: "center", maxWidth: "inherit" }}
            >
              <Button
                variant="contained"
                data-testid={"postSettings"}
                disabled={
                  settings &&
                  settings.preferredLocations &&
                  settings?.preferredLocations?.length <= 0
                }
                onClick={postSettings}
              >
                {t("Confirm")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* booking setting - location select and preferred category select */}
      <Dialog
        onClose={() => setLocationOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={locationOpen}
      >
        <DialogContent>
          <Grid
            data-testid="bookingSetting-location-preferredCategory-combo-grid"
            sx={{ margin: "10px 0 10px 0", minWidth: "20vw", flexDirection: "column" }}
            container
            gap={3}
          >
            <LocationCombo placeholder={t("Location")} />
            <PreferredCategoryCombo />
          </Grid>
        </DialogContent>
      </Dialog>

      {/* automated booking dialog */}
      <Dialog
        onClose={() => setAutomatedOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={automatedOpen}
        PaperProps={{ sx: { maxWidth: 465 } }}
      >
        <DialogContent>
          <Grid justifyContent="flex-start" alignItems={"center"} container direction={"row"}>
            <AutomatedBookingRoutine setAutomatedOpen={setAutomatedOpen} />
          </Grid>
        </DialogContent>
      </Dialog>

      {/* calendar sync and integration dialog */}
      <Dialog
        onClose={() => setCalendarSyncOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={calendarSyncOpen}
        PaperProps={{ sx: { maxWidth: 650 } }}
      >
        <DialogContent>
          <Grid justifyContent="flex-start" alignItems={"center"} container direction={"row"}>
            <CalendarSyncIntegrationBooking setCalendarSyncOpen={setCalendarSyncOpen} />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
