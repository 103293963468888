import { Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { TFunction } from "i18next";
import { Close } from "@mui/icons-material";

type Props = {
  type: "Place" | "Zone";
  showMultiInfoHeader: boolean;
  onClose: () => void;
};

export function MultiInfoHeader({ type, showMultiInfoHeader, onClose }: Props) {
  const headerHeight = "24px";

  return (
    <Grid sx={{ alignItems: "center" }} container>
      <Grid item xs={10} sx={{ height: headerHeight }}>
        {/* title of entry sidebar */}
        <TitleInfoHeader type={type} showMulti={showMultiInfoHeader} />
      </Grid>

      <Grid item xs={1} />
      <Grid item xs={1}>
        <IconButton
          data-testid={"close-btn"}
          onClick={onClose}
          sx={{ float: "right", width: headerHeight, height: headerHeight }}
        >
          <Close fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export function checkEntryName(entry: IPlaceSchedule | IZoneSchedule, t: TFunction) {
  return entry.inventory?.name || t(`new entry (${entry.id})`);
}

export function TitleInfoHeader({
  type,
  showMulti
}: {
  type: "Place" | "Zone";
  showMulti: boolean;
}) {
  const { t } = useTranslation();

  const placeSubject = showMulti ? t("_infoHeaderMultiPlaces") : t("_infoHeaderSinglePlace");
  const zoneSubject = showMulti ? t("_infoHeaderMultiRooms") : t("_infoHeaderSingleRoom");
  const titleType = type === "Place" ? placeSubject : zoneSubject;

  return <Typography variant="subtitle1">{t("_infoHeader", { subject: titleType })}</Typography>;
}
