import { Grid, IconButton, Typography } from "@mui/material";
import { Close, Update } from "@mui/icons-material";
import { IPlaceSchedule } from "../../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";

type P = {
  isBrowser: boolean;
  selectedEntry: IPlaceSchedule | IZoneSchedule;
  setIsDateOpen: (o: boolean) => void;
  onClose: (e: any) => void;
};
export const SelectionPromptHeader: React.FC<P> = props => {
  const { isBrowser, selectedEntry, setIsDateOpen, onClose } = props;

  return (
    <Grid data-testid="selection-prompt-header" container>
      <Grid item xs={isBrowser ? 9 : 8} sx={{ alignSelf: "center" }}>
        <Typography align={"left"} variant={"h5"}>
          {selectedEntry.inventory?.name}
        </Typography>
      </Grid>

      {/* allow to change schedule time */}
      <Grid item xs={isBrowser ? 3 : 4} sx={{ textAlign: "right", placeSelf: "center" }}>
        <IconButton
          data-testid="change-time"
          onClick={() => setIsDateOpen(true)}
          aria-label="change time"
          color={"primary"}
        >
          <Update />
        </IconButton>

        <IconButton data-testid="close-prompt-btn" onClick={onClose} color={"primary"}>
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  );
};
