import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import AlertDialog from "../../components/Dialog/alert-dialog.component";
import { ConfirmStringField } from "../../components/Forms/location-form.component";
import { FloorDialog } from "./FloorDialog/floor-dialog.component";
import { LocationDialog } from "./location-dialog.component";
import { destroyModal, FloorManagerModal } from "./slices/modals.slice";
import deleteFloorById from "./thunks/deleteFloorById";
import deleteLocationByUid from "./thunks/deleteLocationByUid";
import { RootState } from "../../app/rootReducer";
import { FormDialog2 as FormDialog } from "../../components/FacilityManager/Legacy/formDialog2.component";
import fetchAdminLocations from "./thunks/fetchAdminLocations";

const SvgModalsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modals = useSelector((state: RootState) => state.floorManager.modals);

  const { enqueueSnackbar } = useSnackbar();

  const discardModal = (id: string) => {
    dispatch(destroyModal(id));
  };

  const [deleteModalLoading, setDeleteModalLoading] = useState(false);

  return (
    <>
      {modals.map(modal => {
        // Cannot destructure because types are inferred
        switch (modal.modalType) {
          case FloorManagerModal.Location:
            return <LocationDialog modal={modal} key={modal.id} />;

          case FloorManagerModal.Floor:
            return <FloorDialog modal={modal} key={modal.id} />;

          case FloorManagerModal.ConfirmOrCancel:
            return (
              <AlertDialog
                key={modal.id}
                isOpen={modal.isOpen}
                title={modal.initialValues.title ?? t("Alert")}
                description={modal.initialValues.description ?? t("Are you sure?")}
                labelOK={modal.initialValues.labelOK ?? t("Confirm")}
                okAction={e => {
                  discardModal(modal.id);
                  modal.initialValues.okAction && modal.initialValues.okAction(e);
                }}
                cancelAction={e => {
                  discardModal(modal.id);
                  modal.initialValues.cancelAction && modal.initialValues.cancelAction(e);
                }}
              />
            );

          case FloorManagerModal.DeleteLocation:
            return (
              <FormDialog
                title={t("Delete {{thing}}", { thing: t("location") }) + "?"}
                isOpen={modal.isOpen}
                description={t("_deleteLocationWarning", { name: modal.initialValues.name })}
                initialValues={modal.initialValues}
                key={modal.id}
                handleOk={(formVals, helpers) => {
                  if (formVals.confirmString !== modal.initialValues.name) {
                    helpers.setFieldError("confirmString", t("Does not match location's name"));
                  } else {
                    helpers.setFieldError("confirmString", undefined);
                    setDeleteModalLoading(true);
                    dispatch(deleteLocationByUid(modal.initialValues.uid))
                      .then(unwrapResult)
                      .then(() => {
                        setDeleteModalLoading(false);
                        discardModal(modal.id);
                        dispatch(fetchAdminLocations());
                      })
                      .catch(() => {
                        const msg = `${t("There was a problem deleting the {{thing}}", {
                          thing: t("Location")
                        })}.`;

                        enqueueSnackbar(msg, { variant: "error" });
                      });
                  }
                }}
                handleCancel={() => discardModal(modal.id)}
                isLoading={deleteModalLoading}
              >
                <ConfirmStringField
                  placeholder={t(`Enter '{{thing}}'`, { thing: modal.initialValues.name })}
                />
              </FormDialog>
            );

          case FloorManagerModal.DeleteFloor:
            return (
              <FormDialog
                title={t("Delete {{thing}}", { thing: t("floor") }) + "?"}
                description={t("_deleteFloorWarning", { name: modal.initialValues.name })}
                initialValues={modal.initialValues}
                key={modal.id}
                handleOk={(formVals, helpers) => {
                  if (formVals.confirmString !== modal.initialValues.name) {
                    helpers.setFieldError("confirmString", t("Does not match floor's name"));
                  } else {
                    helpers.setFieldError("confirmString", undefined);
                    setDeleteModalLoading(true);
                    dispatch(
                      deleteFloorById({
                        id: modal.initialValues.floorInventoryId,
                        forceDelete: "true"
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        discardModal(modal.id);
                      })
                      .catch(() => {
                        const msg = `${t("There was a problem deleting the {{thing}}", {
                          thing: t("Floor")
                        })}.`;

                        enqueueSnackbar(msg, { variant: "error" });
                      })
                      .finally(() => {
                        setDeleteModalLoading(false);
                      });
                  }
                }}
                handleCancel={() => discardModal(modal.id)}
                isLoading={deleteModalLoading}
                isOpen={modal.isOpen}
              >
                <ConfirmStringField
                  placeholder={t(`Enter '{{thing}}'`, { thing: modal.initialValues.name })}
                />
              </FormDialog>
            );

          default:
            throw new Error("Modal not implemented");
        }
      })}
    </>
  );
};

export default SvgModalsContainer;
