import { useEffect, useState } from "react";
import { Resource, TextStyle, Texture, Matrix } from "pixi.js";
import { Graphics, Sprite, Text } from "@pixi/react";
import { Assets } from "@pixi/assets";
import BoundingBox from "../../Domain/Types/BoundingBox.type";
import EvStationSvg from "./Sprite/electricCharging.svg";
import { ParkPlaceIcon } from "./Sprite/SpriteIndex";
import { useFeatures } from "../../Hooks/useFeatures";

interface Props {
  placeScale: number;
  placeTypeId: number;
  boundingBox: BoundingBox;
  isSelectable: boolean;
  icon?: string;
  isColleaguesPlace?: boolean;
}

export function PlaceIcon({
  placeScale,
  placeTypeId,
  boundingBox,
  isSelectable,
  icon,
  isColleaguesPlace
}: Props) {
  const [iconImage, setIconImage] = useState<Texture<Resource> | undefined>(undefined);
  const { useParkIconText } = useFeatures();

  async function fetchImage(icon: string) {
    const image = (await Assets.load(icon).catch(() =>
      setIconImage(undefined)
    )) as Texture<Resource>;
    setIconImage(image);
  }

  useEffect(() => {
    if (icon && icon.length > 2) fetchImage(icon);
    else setIconImage(undefined);
  }, [icon]);

  const circleRadius = boundingBox.width * placeScale * 0.2 * (isColleaguesPlace ? 2.2 : 1);

  const initialTextPosY_circleCompensat = isColleaguesPlace ? 1.1 : 1.7;
  /** the position y value of initial text of icon */
  const intialTextPosY_Default = 0 - (boundingBox.height * placeScale) / 4;
  /**
   * the position y value correction  of initial text of icon,
   * when place type is parking/electriccharging or colleagues place
   */
  const initialTextPosY_Compensat =
    placeTypeId === 2 || placeTypeId === 4
      ? circleRadius / 1.7
      : circleRadius * initialTextPosY_circleCompensat;
  const initialTextPosY = intialTextPosY_Default - initialTextPosY_Compensat;

  return (
    <>
      {/* text of parking place */}
      {!icon && placeTypeId === 2 && (
        <>
          {/* because of the performance issue, not use the Text object */}
          {useParkIconText && (
            <Text
              data-testid="place-icon-text-parkingplace"
              text={"P"}
              style={new TextStyle({ fill: 0xffffff })}
              resolution={1}
              cursor={isSelectable ? "pointer" : "auto"}
              width={circleRadius * 1.3}
              height={circleRadius * 2}
              position={{
                x: 0 - circleRadius / 1.6,
                y: 0 - (boundingBox.height * placeScale) / 4 - circleRadius
              }}
            />
          )}
          {/* because of the performance issue, use the Sprite object */}
          <Sprite
            data-testid="place-icon-spr-parkingplace"
            image={ParkPlaceIcon}
            cursor={isSelectable ? "pointer" : "auto"}
            width={circleRadius * 1.3}
            height={circleRadius * 2}
            position={{
              x: 0 - circleRadius / 1.6,
              y: 0 - (boundingBox.height * placeScale) / 4 - circleRadius
            }}
          />
        </>
      )}

      {/* icon of electric charging station */}
      {!icon && placeTypeId === 4 && (
        <Sprite
          data-testid="place-icon-electric-charging"
          image={EvStationSvg}
          scale={circleRadius * 0.005}
          x={0 - circleRadius / 1.8}
          y={0 - (boundingBox.height * placeScale) / 4 - circleRadius / 1.6}
          cursor={isSelectable ? "pointer" : "auto"}
        />
      )}

      {/* display initial text of booking users */}
      {icon && icon.length === 2 && (
        <Text
          data-testid="place-icon-text-initial-users"
          text={icon.toUpperCase()}
          resolution={20}
          style={new TextStyle({ fill: isColleaguesPlace ? 0xffffff : 0x000000 })}
          width={circleRadius * 1.3}
          height={circleRadius * 1.3}
          position={{ x: 0 - circleRadius / 1.6, y: initialTextPosY }}
          cursor={isSelectable ? "pointer" : "auto"}
        />
      )}

      {/* display profile image of booking users */}
      {iconImage && (
        <>
          <Graphics
            data-testid="place-icon-grp-user-img"
            draw={g => {
              const mtx = new Matrix();

              const scaleX = (circleRadius * 2) / iconImage.width;
              const scaleY = (circleRadius * 2) / iconImage.height;

              const transX = 0 - (circleRadius * 2) / 2;
              const transY = 0 - (circleRadius * 2) / 2;

              mtx.scale(scaleX, scaleY);
              mtx.translate(transX, transY);

              g.clear();
              g.beginFill(0xffffff);
              g.drawCircle(0, 0, circleRadius);
              g.endFill();
              g.beginTextureFill({ texture: iconImage, matrix: mtx });
              g.drawCircle(0, 0, circleRadius);
              g.endFill();
            }}
            x={0}
            y={placeTypeId === 1 ? 0 - 16 * placeScale : 0 - (boundingBox.height * placeScale) / 4}
            cursor={isSelectable ? "pointer" : "auto"}
          />
        </>
      )}
    </>
  );
}
