import React from "react";
import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import FinanceReport from "../../components/Reports/FinanceReport/FinanceReport";
import FinanceAdministration from "../Admin/finance-administration.component";
import { TabPanelProps } from "../TabPanel/tabPanel.types";
import { CustomColoredTabs } from "../../components/CustomColoredTabs/CustomColoredTabs.component";
import { CustomColoredTab } from "../../components/CustomColoredTabs/CustomColoredTab.component";

function a11yPropsComponent(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanelcomponent-${index}`
  };
}

function TabPanelComponent(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      id={`simple-tabpanelcomponent-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      role="tabpanelcomponent"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box style={{ padding: "20px 0 20px 0" }}>{children}</Box>}
    </div>
  );
}

export default function Finance() {
  const { t } = useTranslation();
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <CustomColoredTabs value={value} onChange={handleChange} theme={theme}>
        <CustomColoredTab
          index={0}
          theme={theme}
          data-testid={"tab1"}
          label={t("Pricing")}
          {...a11yPropsComponent(0)}
        />
        <CustomColoredTab
          index={1}
          theme={theme}
          data-testid={"tab2"}
          label={t("Calculation")}
          {...a11yPropsComponent(1)}
        />
      </CustomColoredTabs>

      <TabPanelComponent value={value} index={0}>
        <FinanceReport />
      </TabPanelComponent>
      <TabPanelComponent value={value} index={1}>
        <FinanceAdministration />
      </TabPanelComponent>
    </div>
  );
}
