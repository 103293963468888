import { useEffect, useState } from "react";
import { Box, Button, Grid, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import DepartmentCard from "./department-card.component";
import AddDepartmentModal from "./add-department-modal.partial";
import { InfoIconWithTooltip } from "../../components/Title/InfoIcon";
import { useRemoteGetAllCostCentersForCompany } from "../../hooks/Remote/Companies/useRemoteGetAllCostCentersForCompany";

export type Department = {
  id?: string;
  companyId: number;
  name: string;
  costCenterResponsibleMail?: string;
  active?: boolean;
  isDepartment?: boolean;
  userInfo?: {
    fullName: string;
    photo?: string | null;
  };
  description?: string;
};

const Departments = () => {
  const {
    userInformation: { companyId }
  } = useSelector((state: RootState) => state.login);

  const { t } = useTranslation();

  const [addDepartment, setAddDepartment] = useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);

  const { data: remoteDepartments, isLoading: isRemoteDepartmentsLoading } =
    useRemoteGetAllCostCentersForCompany(companyId);

  useEffect(() => {
    if (!remoteDepartments) return;
    setDepartments(remoteDepartments);
  }, [remoteDepartments]);

  return (
    <>
      <div style={{ marginRight: 20 }}>
        <InfoIconWithTooltip tooltipText={t("_departmentInfo")} />
      </div>

      <Button
        onClick={() => setAddDepartment(true)}
        style={{ marginBottom: 10 }}
        color={"primary"}
        data-testid="add-departments-btn"
      >
        {t("Add department")}
      </Button>

      {/* is loading */}
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        {isRemoteDepartmentsLoading && (
          <Skeleton variant={"rectangular"} sx={{ height: 240, width: 345, p: "20px", mr: 1 }} />
        )}

        {/* when no departments exist */}
        {!isRemoteDepartmentsLoading && !departments.length && (
          <Skeleton
            variant={"rectangular"}
            sx={{ height: 240, width: 345, p: "20px", mr: 1 }}
            animation={false}
          >
            <Box sx={{ textAlign: "center", alignContent: "center", height: 200, width: 300 }} />
          </Skeleton>
        )}

        {departments.map(department => (
          <DepartmentCard
            key={department.id}
            departmentName={department.name}
            description={department.description}
            active={department.active}
            userInfo={department.userInfo}
            setDepartments={setDepartments}
            costCenterResponsibleMail={department.costCenterResponsibleMail || ""}
            companyId={department.companyId}
            isDepartment={department.isDepartment || false}
            id={department.id || ""}
          />
        ))}
      </Grid>

      <AddDepartmentModal
        open={addDepartment}
        setOpen={setAddDepartment}
        companyId={companyId}
        active={false}
        isDepartment={true}
        departmentName={""}
        setDepartments={setDepartments}
        id={""}
        description={""}
        userInfo={{ fullName: "", photo: null }}
        costCenterResponsibleMail={""}
      />
    </>
  );
};
export default Departments;
