import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "../../../../../../app/helpers";
import { RootState } from "../../../../../../app/rootReducer";
import { SelectionPrompt2 as SelectionPrompt } from "./SelectionPrompt(2)";
import {
  handleSelection,
  selectableByVariant,
  selectedEntry
} from "../Functions/SelectFloorPlanView.functions";
import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { BookingInputs } from "../../../../Domain/Types/BookingInputs.type";
import { ISeatStatus } from "../../../../Domain/Types/FloorPlan/SeatStatus.type";

interface Props {
  floorPlan: IFloorPayload;
  seatStatus: ISeatStatus;
  selectedPlace: number | undefined;
  selectedZone: number | undefined;
  bookingInputs: BookingInputs;
  userIndex: number;
  setSelectedPlace: (place: number | undefined) => void;
  setSelectedZone: (zone: number | undefined) => void;
  setUserIndex: (i: number) => void;
  setIsDateOpen: (o: boolean) => void;
  onSingleSelect?: (placeInventoryId: number) => void;
  onTeamSelect?: (index: number, placeInventoryId: number) => void;
  onNextStep: () => void;
}

export function ResponsiveSelectionPrompt({
  floorPlan,
  seatStatus,
  selectedPlace,
  selectedZone,
  bookingInputs,
  userIndex,
  setSelectedPlace,
  setSelectedZone,
  setUserIndex,
  setIsDateOpen,
  onSingleSelect,
  onTeamSelect,
  onNextStep
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  // when is mobile or tablet, display selection prompt as dialog in the center
  const isBrowser = useMediaQuery(theme.breakpoints.up(1200));

  const { bookingType } = useSelector((state: RootState) => state.booking.inputs);
  const { userInformation } = useSelector((state: RootState) => state.login);

  const isSelectedEntry = (() => selectedEntry(floorPlan, selectedPlace, selectedZone))();
  const isSelectableByVariant = (() =>
    selectableByVariant(
      selectedEntry(floorPlan, selectedPlace, selectedZone),
      seatStatus,
      bookingType
    ))();

  const handleConfirmSelection = () => {
    handleSelection(
      bookingInputs,
      userIndex,
      floorPlan,
      { selectedPlace, selectedZone },
      { setSelectedPlace, setUserIndex },
      { onSingleSelect, onTeamSelect, onNextStep },
      { enqueueSnackbar, t }
    );
  };

  const closeSelectionPrompt = (e: any) => {
    e.stopPropagation();
    setSelectedPlace(undefined);
    setSelectedZone(undefined);
  };

  return (
    <>
      <Dialog
        open
        maxWidth={isBrowser ? false : "md"}
        fullWidth={isBrowser ? false : true}
        onClose={closeSelectionPrompt}
      >
        <SelectionPrompt
          selected={isSelectedEntry}
          userIndex={userIndex}
          userId={userInformation.sub}
          setIsDateOpen={setIsDateOpen}
          selectableByVariant={isSelectableByVariant}
          onConfirm={handleConfirmSelection}
          onClose={closeSelectionPrompt}
          bookingInputs={bookingInputs}
        />
      </Dialog>
    </>
  );
}
