import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Popover,
  Input,
  Autocomplete
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { TwitterPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import useScript from "react-script-hook";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { LocationInventory } from "../../features/FloorManager/typings/location-inventory";
import { PricingModel } from "../../features/Login/typings/login.types";
import { getMuiErrorHelperText, getMuiPropsFromFormikContext } from "../../utils/form.utils";
import { PictureDropzone } from "../Pickers/picture-dropzone.component";
import { GoogleMapPicker } from "./google-map-picker3.component";
import { NumericFormatCustom } from "./checkin-radius-numericFormat.component";
import {
  RemoteCompany,
  useRemoteFetchCompanies
} from "../../hooks/Remote/Companies/useRemoteFetchCompanies";

/**
 * Basic inputs for location inventory.
 */
const LocationInputs: React.FC = () => {
  const { t } = useTranslation();

  const formikContext = useFormikContext<LocationInventory>();
  const { values, handleChange, setFieldValue, errors, setValues } = formikContext;
  const {
    userInformation: {
      pricingModels,
      company: {
        meta: { color: brandColor, companyId: userCompanyId }
      }
    }
  } = useSelector((state: RootState) => state.login);

  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&libraries=places`;
  const [scriptLoading, scriptError] = useScript({ src: scriptSrc });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openEl = Boolean(anchorEl);

  const { data: remoteCompanies, isFetching: isRemoteCompaniesFetching } =
    useRemoteFetchCompanies();

  const [selectedCompany, setSelectedCompany] = useState<RemoteCompany | null>(null);

  useEffect(() => {
    // if company for the location is not initially existed, then attach the log-in user's company by the default
    if (values.schedule) {
      const latestIndex = values.schedule.findIndex(schedule => schedule.end === null);
      const scheduleCompanyId = values.schedule[latestIndex].companyId;
      setSelectedCompany(remoteCompanies?.find(comp => comp.id === scheduleCompanyId) ?? null);
    } else setSelectedCompany(remoteCompanies?.find(comp => comp.id === userCompanyId) ?? null);
  }, [remoteCompanies]);

  useEffect(() => {
    setFieldValue("bookingAllowedAt", values.allowForBooking ? new Date().toISOString() : "");
    setFieldValue("checkinRadiusInMeters", values.checkinRadiusInMeters ?? 1500);
  }, [values.allowForBooking, values.checkinRadiusInMeters, setFieldValue]);

  return (
    <>
      {/* name of location form */}
      <TextField
        {...getMuiPropsFromFormikContext(formikContext, "name", t)}
        type="text"
        onChange={handleChange}
        margin="dense"
        fullWidth
        sx={{ mb: 1 }}
      />

      <Grid
        container
        sx={{ justifyContent: "flex-start", alignItems: "center", mb: 1 }}
        data-testid="location-form-address-and-checkin-period"
      >
        {/* location form address picker */}
        <Box>{!scriptLoading && !scriptError && <GoogleMapPicker />}</Box>

        {/* checkin-period select */}
        <FormControl style={{ margin: 20, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">{t("Check-in Period")}</InputLabel>
          <Select
            data-testid="check-in-period-select"
            sx={{ width: 150, marginLeft: 1.8 }}
            margin="dense"
            name="checkinPeriod"
            id="checkinPeriod"
            defaultValue={15}
            label={t("Check-In Period")}
            placeholder={t("30 minutes")}
            value={values.checkinPeriod}
            onChange={handleChange}
            variant={"standard"}
          >
            <MenuItem value={15}>{t("15 Minutes")}</MenuItem>
            <MenuItem value={30}>{t("30 Minutes")}</MenuItem>
            <MenuItem value={45}>{t("45 Minutes")}</MenuItem>
            <MenuItem value={-1}>{t("Unlimited")}</MenuItem>
          </Select>
        </FormControl>

        {/* checkin-radius field */}
        <FormControl style={{ margin: 20, maxWidth: 160 }}>
          <InputLabel>{`${t("Check-In Radius")} (m)`}</InputLabel>
          <Input
            sx={{ marginLeft: 1.8 }}
            {...getMuiPropsFromFormikContext(formikContext, "checkinRadiusInMeters", t)}
            data-testid="check-in-radius-input"
            value={values.checkinRadiusInMeters}
            onChange={handleChange}
            inputComponent={NumericFormatCustom as any} // type="number"
            // inputProps={{ min: 0.1, step: 0.1 }}
            error={!values.checkinRadiusInMeters}
          />
        </FormControl>
      </Grid>

      <Grid container sx={{ columnGap: 3 }}>
        <TextField
          {...getMuiPropsFromFormikContext(formikContext, "maxLevels", t)}
          margin="dense"
          type="number"
          label={t("Max Levels")}
          onChange={handleChange}
          InputProps={{ inputProps: { min: 1 } }}
        />

        {/* company assign for the location */}
        <FormControl sx={{ margin: 1, minWidth: 120 }}>
          <Autocomplete
            data-testid="select-company-for-loc"
            loading={isRemoteCompaniesFetching}
            options={remoteCompanies ?? []}
            renderOption={(props, option) => (
              <li {...props} key={option.refId}>
                <div id={option.name} data-testid={`company-opt-${option.name}`}>
                  {option.name}
                </div>
              </li>
            )}
            isOptionEqualToValue={(option, value) =>
              option.name === value.name && option.refId === value.refId
            }
            // defaultValue={remoteCompanies?.find(comp => comp.id === userCompanyId)}
            value={selectedCompany}
            onChange={(e, value) => {
              setSelectedCompany(value);

              // when assigning company, update schedule start & end as well
              const newSchedule = values.schedule;

              if (newSchedule) {
                const latestIndex = newSchedule.findIndex(schedule => schedule.end === null);
                newSchedule[latestIndex].start = DateTime.now().toISO();
                newSchedule[latestIndex].end = null;
                newSchedule[latestIndex].companyId = value?.id ?? 0;
              }

              setValues({ ...values, schedule: newSchedule });
            }}
            renderInput={params => (
              <>
                <InputLabel
                  sx={{
                    fontSize: "0.76rem",
                    top: "-36%",
                    left: "-5%",
                    color: !selectedCompany ? "#d32f2f" : "primary"
                  }}
                >
                  {t("Company")}
                </InputLabel>
                <TextField
                  {...params}
                  error={!selectedCompany}
                  helperText={!selectedCompany ? t("Select the company for this location.") : ""}
                  data-testid="select-company-for-loc-field"
                  sx={{ minWidth: 250 }}
                  InputProps={{ ...params.InputProps, sx: { height: 48, alignItems: "end" } }}
                />
              </>
            )}
            getOptionLabel={option => option.name ?? ""}
          />
        </FormControl>
      </Grid>

      <Grid container sx={{ mt: 1.5, mb: 2.5 }}>
        <Grid
          item
          xs={6}
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          {pricingModels.includes(PricingModel.ENTERPRISE) && (
            <FormControlLabel
              control={
                <Switch
                  checked={values.allowChangeDeskCost ?? false}
                  onChange={handleChange}
                  name="allowChangeDeskCost"
                  id="allowChangeDeskCost"
                  color="primary"
                />
              }
              label={t("Allow changing desk cost?")}
            />
          )}

          <FormControlLabel
            control={
              <Switch
                checked={values.allowForBooking ?? false}
                onChange={handleChange}
                name="allowForBooking"
                id="allowForBooking"
                color="primary"
              />
            }
            label={t("Allow bookings?")}
          />
        </Grid>

        <Grid
          item
          xs={6}
          sx={{ justifyContent: "flex-start", alignItems: "center", width: "100%" }}
        >
          <Typography variant={"body2"}>{t("Calendar Color")}</Typography>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Box
              data-testid={"box-loc-color"}
              sx={{
                background: values.color || brandColor || "",
                width: "4rem",
                height: 15,
                borderRadius: 6,
                marginRight: 2
              }}
            />
            <IconButton
              onClick={e => setAnchorEl(e.currentTarget)}
              size={"small"}
              data-testid={"change-loc-color"}
              aria-label={"change Color"}
            >
              <Edit />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      {/* change location color */}
      <Popover
        disableEnforceFocus={true}
        open={openEl}
        anchorEl={anchorEl}
        /* istanbul ignore next */
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Grid
          container
          style={{ marginTop: 10, height: 100 }}
          data-testid="twitter-color-picker-grid"
        >
          <TwitterPicker
            color={values.color || brandColor || ""}
            onChange={props => setFieldValue("color", props.hex)}
          />
        </Grid>
      </Popover>

      <PictureDropzone
        title={t("Upload an image")}
        files={(values as any).file ?? []}
        onAdd={files => {
          setFieldValue("file", files);
          setFieldValue("picturePath", "temp");
        }}
        onDelete={() => {
          setFieldValue("file", undefined);
          setFieldValue("picturePath", "");
        }}
        current={values.picturePath ?? undefined}
        error={!(values as any).file}
        helperText={t((errors as any).file)}
      />

      {/* picture delete button */}
      {values.picturePath && values.picturePath !== "temp" && (
        <Button
          onClick={() => {
            setFieldValue("file", undefined);
            setFieldValue("picturePath", "");
          }}
          data-testid="delete-picture-btn"
        >
          {t("Delete picture")}
        </Button>
      )}
    </>
  );
};

export const ConfirmStringField: React.FC<{ placeholder?: string; label?: string }> = ({
  placeholder,
  label
}) => {
  const { handleChange, touched, errors } = useFormikContext<{ confirmString: string }>();
  const { t } = useTranslation();

  return (
    <Grid>
      <TextField
        name="confirmString"
        onChange={handleChange}
        label={label || t("Confirm")}
        placeholder={placeholder}
        {...getMuiErrorHelperText("confirmString", touched, errors)}
        fullWidth
      />
    </Grid>
  );
};

export default LocationInputs;
