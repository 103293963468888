import { useEffect, useMemo, useState } from "react";
import {
  MRT_BottomToolbar,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import { Button, Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { commonLocalization } from "../../../../../../functions/tableLocalization";
import { BookingPropertyRow } from "./typings/BookingPropertyTableRow.type";
import { handleInitialBookingProp } from "./BookingPropertyAssignTable.partial";

type Props = {
  subjectEntity: IPlaceSchedule[] | IZoneSchedule[];
  entries: BookingPropertyRow[] | undefined;
  onAssign: (bookProp: BookingPropertyRow[]) => void;
  onClose: () => void;
};

export function BookingPropertyAssignTable({ subjectEntity, entries, onAssign, onClose }: Props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tablePaperThemeColor = theme.palette.background.paper;

  const [data, setData] = useState<BookingPropertyRow[]>();
  /** handle row select and handle initial value from floor plan, which value is already assigned */
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({}); // Record<string, boolean> ({ "1": true });

  useEffect(() => {
    setData(entries);
  }, [entries]);

  useEffect(() => {
    handleInitialBookingProp(subjectEntity, setRowSelection);
  }, [subjectEntity]);

  const column = useMemo<MRT_ColumnDef<BookingPropertyRow>[]>(
    () => [
      {
        accessorKey: "name",
        header: t("Name"),
        size: 200,
        enableEditing: false
      },
      {
        accessorKey: "description",
        header: t("Description"),
        size: 600,
        enableEditing: false
      }
    ],
    [data, i18n.language]
  );

  const table = useMaterialReactTable({
    columns: column ?? [],
    data: data ?? [],
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enableRowSelection: true,
    getRowId: row => row.id?.toString(),
    onRowSelectionChange: setRowSelection,
    state: {
      isLoading: !entries?.length,
      rowSelection
    },
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: { pageSize: 5, pageIndex: 0 }
    },
    positionToolbarAlertBanner: "none",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined"
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15],
      variant: "outlined"
    },
    muiTablePaperProps: {
      sx: { backgroundColor: tablePaperThemeColor, boxShadow: "none", width: "inherit" }
    },
    muiTableContainerProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiTableBodyCellProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiTableHeadCellProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiTopToolbarProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiBottomToolbarProps: { sx: { backgroundColor: tablePaperThemeColor } },
    paginationDisplayMode: "pages",
    localization: {
      ...commonLocalization(t),
      noRecordsToDisplay: t("_editable_list_noRecords", { item: t("_noBookingProperty") })
    },
    renderBottomToolbar: ({ table }) => {
      return (
        <Grid>
          <Grid item>
            <MRT_BottomToolbar table={table} />
          </Grid>
          <Grid item textAlign={"end"} sx={{ mt: 3 }}>
            <Button data-testid="cancel-btn" onClick={onClose} color="secondary" sx={{ mr: 2 }}>
              {t("Cancel")}
            </Button>
            <Button
              data-testid="assign-btn"
              onClick={() => {
                onAssign(table.getSelectedRowModel().rows.map(row => row.original));
                onClose();
              }}
            >
              {t("Assign")}
            </Button>
          </Grid>
        </Grid>
      );
    }
  });

  return (
    <Grid container>
      <MaterialReactTable table={table} />
    </Grid>
  );
}
