import { FormLabel, Grid } from "@mui/material";
import { Datepicker, locale } from "@mobiscroll/react";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import generateDisabledWeekdays from "./DateTimePicker/generate-disabled-weekdays.function";
import useTimeFormat from "../../hooks/useTimeFormat/useTimeFormat";
import { findMaxAdvancedBookingSettingPerType } from "./utils/booking.utils";

const SpecificRecurrenceForm: React.FC = () => {
  const [displayReduxValue, setDisplayReduxValue] = useState<boolean>(true);
  const [tmpSpecificDays, setTmpSpecificDays] = useState<string[]>([]);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { dateFormat } = useTimeFormat();

  const { specificDays, mode } = useSelector((state: RootState) => state.booking.inputs);

  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);
  const maxDate = DateTime.now()
    .plus({
      weeks:
        mode !== BookingType.PLACEZONE
          ? findMaxAdvancedBookingSettingPerType(mode, metaData.advancedBookingSettings)
          : metaData.maxAdvancedZoneBookingLength
    })
    .toFormat("yyyy-MM-dd");

  // checks if sa and so are allowed and generates the datepicker invalid string
  const disabledOnWeekDays = useMemo(
    () =>
      generateDisabledWeekdays({
        bookingsOnSaturdaysAllowed: metaData.bookingsOnSaturdaysAllowed,
        bookingsOnSundaysAllowed: metaData.bookingsOnSundaysAllowed
      }),
    [metaData]
  );

  const getFromAndToDates = (specificDaysPicker: string[]): string[] => {
    const currentDate = specificDaysPicker[0] || DateTime.now().toFormat("yyyy-MM-dd");

    let localTmpBookingFrom: DateTime = DateTime.fromISO(currentDate, { zone: "utc" });
    let localTmpBookingTo: DateTime = DateTime.fromISO(currentDate, { zone: "utc" });

    specificDaysPicker.forEach(date => {
      const luxonDate = DateTime.fromISO(date, { zone: "utc" });

      localTmpBookingFrom = DateTime.min(localTmpBookingFrom, luxonDate);
      localTmpBookingTo = DateTime.max(localTmpBookingTo, luxonDate);
    });

    return [localTmpBookingFrom.toFormat("yyyy-MM-dd"), localTmpBookingTo.toFormat("yyyy-MM-dd")];
  };

  const updateReduxState = (values: string[]) => {
    const [tmpBookingFrom, tmpBookingTo] = getFromAndToDates(values);
    dispatch(
      setInputs({
        specificDays: values,
        bookingFrom: tmpBookingFrom,
        bookingTo: tmpBookingTo
      })
    );
  };

  const selectedState = displayReduxValue ? specificDays : tmpSpecificDays;

  return (
    <Grid>
      <FormLabel style={{ marginLeft: "1em" }}>{t("Specific days")}</FormLabel>
      <Datepicker
        select="date"
        placeholder={t("_selectSpecificDates")}
        data-testid={"dateSpecific"}
        selectMultiple={true}
        returnFormat="iso8601"
        invalid={disabledOnWeekDays}
        dateFormat={dateFormat.toUpperCase()}
        locale={locale[i18n.language]}
        onOpen={() => {
          setTmpSpecificDays(specificDays || []);
          setDisplayReduxValue(false);
        }}
        onClose={picker => {
          updateReduxState((picker.value as string[]) || []);
          setDisplayReduxValue(true);
        }}
        onChange={(picker, inst) => {
          const value = (picker.value as string[]) || [];

          setTmpSpecificDays(value);

          if (!inst?.isVisible()) {
            updateReduxState(value);
          }
        }}
        value={selectedState}
        min={DateTime.now().toFormat("yyyy-MM-dd")}
        max={maxDate}
      />
    </Grid>
  );
};
export default SpecificRecurrenceForm;
