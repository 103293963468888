import { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Switch,
  Typography
} from "@mui/material";
import { Delete, LockPerson, MeetingRoom } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import qrcodeIcon from "@iconify/icons-mdi/qrcode";
import { useSnackbar } from "notistack";
import { InfoSideBar } from "../InfoSidebar";
import { IZoneUserNumber } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { InfoBox } from "../InfoBox";
import { CompactTextField } from "../../../../../Common/CompactTextfield/CompactTextfield";
import { AssignBox } from "../AssignBox/AssignBox";
import { ZoneInventoryDialogContent } from "./ZoneInventoryDialogContent/ZoneInventoryDialogContent";
import { useInventory } from "../../../../Hooks/useInventory";
import { OptionDialogContent } from "./OptionDialogContent/OptionDialogContent";
import { ZonePermissionDialogContent } from "./ZonePermissionDialogContent/ZonePermissionDialogContent";
import { ZoneAccessControlDialogContent } from "./AccessControlDialogContent/ZoneAccessControlDialogContent";
import { QrCodeComponent } from "../../../Modals/QrCodeModal/QrCodeComponent";
import { useSelector } from "../../../../../../app/helpers";
import { RootState } from "../../../../../../app/rootReducer";
import { MultiInfoHeader } from "../MultiInfoHeader/MultiInfoHeader";
import { MiniTextInput } from "../../../../../Common/MiniTextInput/MiniTextInput";
import { InfoIconWithTooltip } from "../../../../../Title/InfoIcon";
import {
  generateApprovalRequiredLabel,
  generateAutomatedApprovalLabel,
  generateDisabledLabel,
  handlePatchZoneInventory,
  handleUserNumbersInput,
  initialUserNumbers,
  isEntryAssigned,
  updateAssingedEquipment,
  updateMutatedZoneInventory
} from "./ZoneInfoSidebar.partial";
import { ZoneInfoSidebarProps } from "./ZoneInfoSidebar.switch";
import { IEquipmentInventory } from "../../../../Domain/Types/FloorPlan/Equipment.type";
import { AssignEntryTableContainer } from "../EquipmentCategoryDialogContent/AssignEntryTableContainer";
import { EquipmentCategoryAssignTable } from "../EquipmentCategoryDialogContent/EquipmentCategoryAssignTable";
import { BookingPropertyAssignTable } from "../BookingPropertyDialogContent/BookingPropertyAssignTable";
import { initMoreItemName } from "../functions/initMoreItemName.function";
import { adjustZoneType } from "../../../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";

export const ZoneInfoSidebar = forwardRef<HTMLDivElement, ZoneInfoSidebarProps>(
  (
    {
      height,
      zoneSchedules,
      floorPlan,
      isZnInvModalOpen,
      hasSiteId,
      setIsZnInvModalOpen,
      onClose,
      onClickDelete,
      onChangeZoneDescription,
      onChangeName,
      onChangePerm,
      onChangeEnabledStatus,
      onChangeApproval,
      onChangeEquipCate,
      onChangeBookProp,
      onChangeUserNumber,
      updateZoneInventory,
      inventoryManagement,
      categoryManagement,
      equipmentManagement,
      floorPlanName
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const {
      settings: { colorMode }
    } = useSelector((state: RootState) => state.login);
    const iconColor = colorMode === "light" ? "#6f6f6f" : "white";

    const selectedIds = zoneSchedules.map(m => m.id);

    const zoneTypeId =
      zoneSchedules[0]?.zoneTypeId ||
      zoneSchedules[0]?.inventory?.zoneTypeId ||
      zoneSchedules[0]?.inventory?.zoneType?.id;

    const availableEquipment = floorPlan.freeInventories.equipments.filter(
      d => !isEntryAssigned(d, floorPlan.zones)
    );

    const zoneTypeName =
      adjustZoneType(floorPlan.zoneTypes).find(type => type.id === zoneTypeId)?.name || "";

    const { minUsers, maxUsers } = initialUserNumbers(zoneSchedules);

    const [zoneName, setZoneName] = useState("");
    const [zoneDesc, setZoneDesc] = useState("");
    const [zoneUserNumber, setZoneUserNumber] = useState<IZoneUserNumber>({
      minUsers: minUsers,
      maxUsers: maxUsers
    });

    const [isZoneCategoryModalOpen, setIsZoneCategoryModalOpen] = useState(false);
    const [isEquipmentModalOpen, setIsEquipmentModalOpen] = useState(false);
    const [isZnPermDialogOpen, setIsZnPermDialogOpen] = useState(false);
    const [isAccessControlOpen, setIsAccessControlOpen] = useState(false);
    const [isEquipCateDialogOpen, setIsEquipCateDialogOpen] = useState(false);
    const [isPropertyDialogOpen, setIsPropertyDialogOpen] = useState(false);

    const [isQrModalOpen, setIsQrModalOpen] = useState(false);

    const [assignedEquipment, setAssginedEquipment] = useState<IEquipmentInventory[]>([]);

    const { handleCreateZoneInventory, mutatedZoneInventory } = useInventory({ floorPlan });

    // check if multi selected zone type includes conference zone
    const isConferenceOnlySelected = zoneSchedules
      .map(z => z.zoneTypeId || z.inventory?.zoneTypeId)
      .every(v => v === 3);

    // length of selected zone
    const singleZoneSelected = zoneSchedules.length === 1;

    // check if multi selected zone have same type
    const isIdenticalZoneTypeSelected = zoneSchedules
      .map(z => z.zoneTypeId || z.inventory?.zoneTypeId)
      .every(v => v === zoneTypeId);

    useEffect(() => {
      if (zoneSchedules) {
        setZoneName(zoneSchedules[0]?.inventory?.name ?? "");
        setZoneDesc(zoneSchedules[0]?.description ?? "");
        setZoneUserNumber({
          minUsers: minUsers,
          maxUsers: maxUsers
        });
      }

      updateAssingedEquipment(zoneSchedules, setAssginedEquipment);
    }, [zoneSchedules]);

    useEffect(() => {
      updateMutatedZoneInventory(mutatedZoneInventory, zoneSchedules, updateZoneInventory);
    }, [mutatedZoneInventory]);

    return (
      <InfoSideBar height={height} width={290} overflow="scroll" ref={ref}>
        {zoneSchedules.length > 0 && (
          <InfoBox color={"#00000088"}>
            {/* header of multi zones edit and listing the names */}
            <MultiInfoHeader
              type={"Zone"}
              showMultiInfoHeader={zoneSchedules.length > 1}
              onClose={onClose}
            />

            {/* name of the zone */}
            <CompactTextField
              placeholder={t("Name")}
              value={zoneName}
              rows={2}
              onChange={newText => {
                onChangeName(selectedIds, newText);
                setZoneName(newText);
              }}
              handleOk={newText => {
                for (const zone of zoneSchedules) {
                  handlePatchZoneInventory({
                    zoneInventory: zone.inventory,
                    enqueueSnackbar,
                    t,
                    newName: newText
                  });
                }
              }}
              newText={zoneName}
              setNewText={(t: string) => setZoneName(t)}
            />

            {/* description of the zone */}
            <CompactTextField
              rows={2}
              placeholder={t("Description")}
              value={zoneDesc}
              onChange={desc => onChangeZoneDescription(desc)}
              newText={zoneDesc}
              setNewText={(t: string) => setZoneDesc(t)}
            />

            {/* type of the zone */}
            {isIdenticalZoneTypeSelected && (
              <AssignBox
                buttonDataTestId="type-assign-box"
                value={
                  zoneSchedules[0].zoneTypeId ||
                  zoneSchedules[0].inventory?.zoneType?.id ||
                  zoneSchedules[0].inventory?.zoneTypeId
                }
                name={zoneTypeName}
                type={"Type"}
              />
            )}

            {/** inventory */}
            {singleZoneSelected && (
              <AssignBox
                buttonDataTestId="inventory-assign-box"
                onClickAssign={() => setIsZnInvModalOpen(true)}
                value={zoneSchedules[0].inventory?.id}
                type={"Inventory"}
                name={zoneSchedules[0].inventory?.name}
              />
            )}

            {/** category */}
            <AssignBox
              onClickAssign={() => setIsZoneCategoryModalOpen(true)}
              value={zoneSchedules[0].category?.id}
              type={"Category"}
              buttonDataTestId="assign-category-btn"
              name={zoneSchedules[0].category?.name}
            />

            {/** equipment */}
            {singleZoneSelected && (
              <AssignBox
                buttonDataTestId="equipment-assign-box"
                onClickAssign={() => setIsEquipmentModalOpen(!isEquipmentModalOpen)}
                value={
                  assignedEquipment.length
                    ? t(`${assignedEquipment.length} (pcs)`)
                    : t("NoEquipmentSidebarLabel")
                }
                type={"Equipment"}
                valueArr={assignedEquipment.map(eq => eq.name)}
                name={initMoreItemName(assignedEquipment, "Equipment", t)}
              />
            )}

            {/** equipment category */}
            <AssignBox
              buttonDataTestId="equipment-category-assign-box"
              onClickAssign={() => setIsEquipCateDialogOpen(!isEquipCateDialogOpen)}
              value={0}
              type={"Equipment_category"}
              name={initMoreItemName(zoneSchedules[0].equipmentCategories, "Equipment_category", t)}
            />

            {/** properties */}
            <AssignBox
              buttonDataTestId="book-properties-assign-box"
              onClickAssign={() => setIsPropertyDialogOpen(!isPropertyDialogOpen)}
              value={0}
              type={"Properties"}
              name={initMoreItemName(zoneSchedules[0].bookingProperties, "Properties", t)}
            />

            {/** property for conference zone */}
            {singleZoneSelected && isConferenceOnlySelected && (
              <>
                {/** min and max users number */}
                <Grid
                  container
                  data-testid="zone-userNumber-input"
                  sx={{ pt: "0.3rem", flexWrap: "nowrap" }}
                >
                  <Grid item>
                    <MiniTextInput
                      fields={[
                        {
                          value: zoneUserNumber.minUsers,
                          placeholder: "Min",
                          id: "min",
                          type: "number"
                        },
                        {
                          value: zoneUserNumber.maxUsers,
                          placeholder: "Max",
                          id: "max",
                          type: "number"
                        }
                      ]}
                      onInput={(id, value) => {
                        handleUserNumbersInput(
                          id,
                          value,
                          zoneSchedules[0].id,
                          zoneUserNumber,
                          setZoneUserNumber,
                          onChangeUserNumber,
                          enqueueSnackbar
                        );
                      }}
                      zoneUserNumber={zoneUserNumber}
                      setZoneUserNumber={(n: IZoneUserNumber) => setZoneUserNumber(n)}
                    />
                  </Grid>
                  <Grid item data-testid="user-number-info-desc" sx={{ pt: "2px", pl: "2px" }}>
                    <InfoIconWithTooltip tooltipText={t("EditInfoUserNumberForConferenceZone")} />
                  </Grid>
                </Grid>
              </>
            )}

            {/** Settings */}
            <AssignBox
              buttonDataTestId="settings-assign-box"
              type={"Settings"}
              bottomChildren={
                <Box sx={{ p: 0.5 }}>
                  <Grid container sx={{ placeContent: "space-between", alignItems: "center" }}>
                    {/* assign permissions */}
                    <Grid item data-testid="assign-perm-item" display={"contents"}>
                      <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          sx={{ cursor: "default" }}
                        >
                          <LockPerson />
                        </IconButton>
                        <Typography sx={{ fontSize: 13, pl: 0.5 }}>{t("Permissions")}</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant={"text"}
                          data-testid={"permission-btn"}
                          sx={{ fontSize: 12 }}
                          onClick={() => setIsZnPermDialogOpen(true)}
                        >
                          {t("Assign")}
                        </Button>
                      </Grid>
                    </Grid>

                    {/* access control */}
                    {singleZoneSelected && hasSiteId && (
                      <Grid item data-testid="access-control-item" display={"contents"}>
                        <Grid item sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            sx={{ cursor: "default" }}
                          >
                            <MeetingRoom />
                          </IconButton>
                          <Typography sx={{ fontSize: 13, pl: 0.5 }}>
                            {t("Access Control")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant={"text"}
                            data-testid={"zone-access-btn"}
                            sx={{ fontSize: 12 }}
                            onClick={() => setIsAccessControlOpen(true)}
                            disabled={!zoneSchedules[0].inventory?.id}
                          >
                            {t("Assign")}
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Divider sx={{ mt: 0.5, mb: 0.5 }} />

                  <Box display={"flex"} flexDirection={"column"}>
                    <FormControlLabel
                      labelPlacement={"end"}
                      sx={{ fontSize: "12px", ml: 0, mr: 0, mb: 0.5 }}
                      control={
                        <Switch
                          inputProps={{ "data-testid": "enabled-switch" } as any}
                          size={"small"}
                          sx={{ marginRight: 1 }}
                          checked={!zoneSchedules[0].disabled}
                          onChange={e => onChangeEnabledStatus(selectedIds, e.target.checked)}
                        />
                      }
                      label={
                        <Typography fontSize={"12px"}>
                          {generateDisabledLabel(!zoneSchedules[0].disabled)}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      labelPlacement={"end"}
                      sx={{ fontSize: "12px", ml: 0, mr: 0, mb: 0.5 }}
                      control={
                        <Switch
                          data-testid={"approvalRequired-switch"}
                          size={"small"}
                          sx={{ marginRight: 1 }}
                          checked={zoneSchedules[0].approvalRequired}
                          onChange={e =>
                            onChangeApproval(selectedIds, { approvalRequired: e.target.checked })
                          }
                        />
                      }
                      label={
                        <Typography fontSize={"12px"}>
                          {generateApprovalRequiredLabel(zoneSchedules[0].approvalRequired)}
                        </Typography>
                      }
                    />
                    {zoneSchedules[0].approvalRequired && (
                      <FormControlLabel
                        labelPlacement={"end"}
                        sx={{ fontSize: "12px", ml: 0, mr: 0, mb: 0.5 }}
                        control={
                          <Switch
                            data-testid={"automatedApproval-switch"}
                            size={"small"}
                            sx={{ marginRight: 1 }}
                            checked={zoneSchedules[0].automatedApproval}
                            onChange={e =>
                              onChangeApproval(selectedIds, { automatedApproval: e.target.checked })
                            }
                          />
                        }
                        label={
                          <Typography fontSize={"12px"}>
                            {generateAutomatedApprovalLabel(zoneSchedules[0].automatedApproval)}
                          </Typography>
                        }
                      />
                    )}
                  </Box>
                </Box>
              }
            />

            {/** bottom bar */}
            <Box
              display={"flex"}
              alignItems={"center"}
              data-testid="bottom-bar-box"
              sx={{ pr: 1, justifyContent: "space-between" }}
            >
              {/* qr code print for conference zone */}
              {isConferenceOnlySelected && (
                <Box>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      data-testid="tool-btn-printQr"
                      onClick={() => setIsQrModalOpen(!isQrModalOpen)}
                    >
                      <Icon icon={qrcodeIcon} color={iconColor} width={26} height={26} />
                    </IconButton>
                    <Typography fontSize={"12px"}>
                      {zoneSchedules.length > 1 ? t("MultiQrCodePrint") : t("SingleQrCodePrint")}
                    </Typography>
                  </div>
                </Box>
              )}

              <Box display={"flex"} alignItems={"center"}>
                <IconButton data-testid={"delete-btn"} onClick={() => onClickDelete(selectedIds)}>
                  <Delete />
                </IconButton>
                <Typography fontSize={"12px"}> {t("Delete")}</Typography>
              </Box>
            </Box>

            {/* zone permission modal */}
            <Modal
              open={isZnPermDialogOpen}
              onClose={() => setIsZnPermDialogOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <ZonePermissionDialogContent
                  selectedZones={zoneSchedules}
                  onChangePerm={onChangePerm}
                  onClose={() => setIsZnPermDialogOpen(false)}
                />
              </>
            </Modal>

            {/* zone inventory modal */}
            <Modal
              open={isZnInvModalOpen}
              onClose={() => setIsZnInvModalOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <ZoneInventoryDialogContent
                  selectedZone={zoneSchedules[0]}
                  floorPlan={floorPlan}
                  inventoryManagement={inventoryManagement}
                  handleCreateZoneInventory={handleCreateZoneInventory}
                  onClose={() => setIsZnInvModalOpen(false)}
                  setZoneName={(n: string) => setZoneName(n)}
                />
              </>
            </Modal>

            {/* zone category modal */}
            <Modal
              open={isZoneCategoryModalOpen}
              onClose={() => setIsZoneCategoryModalOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <OptionDialogContent
                  pivotEntityName="Zone"
                  subjectEntityName="Category"
                  onClose={() => setIsZoneCategoryModalOpen(false)}
                  displayHeaderId={zoneSchedules[0].id}
                  displayChipValue={zoneSchedules[0].category?.name || 0}
                  subjectEntity={zoneSchedules}
                  subjectEntityEntries={floorPlan.zoneCategories}
                  updateFunctionSub1={categoryManagement.changeZoneCategory}
                />
              </>
            </Modal>

            {/* zone Equipment assignment modal */}
            <Modal
              open={isEquipmentModalOpen}
              onClose={() => setIsEquipmentModalOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <OptionDialogContent
                  subjectEntity={zoneSchedules[0]}
                  subjectEntityName={"Equipment"}
                  displayHeaderId={zoneSchedules[0].id}
                  displayChipValue={""}
                  subjectEntityEntries={[
                    { availableEquipment: availableEquipment, assignedEquipment: assignedEquipment }
                  ]}
                  pivotEntityName="Zone"
                  onClose={() => setIsEquipmentModalOpen(false)}
                  updateFunctionSub2={equipmentManagement.changeZoneEquipments}
                />
              </>
            </Modal>

            {/* equipment category modal */}
            <Modal
              open={isEquipCateDialogOpen}
              /* istanbul ignore next */
              onClose={() => setIsEquipCateDialogOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <AssignEntryTableContainer
                subjectEntity={zoneSchedules}
                tableChild={
                  <EquipmentCategoryAssignTable
                    subjectEntity={zoneSchedules}
                    entries={floorPlan.equipmentCategories}
                    onAssign={equipCate => onChangeEquipCate(selectedIds, equipCate)}
                    onClose={() => setIsEquipCateDialogOpen(false)}
                  />
                }
                type={t("Equipment category")}
                onClose={() => setIsEquipCateDialogOpen(false)}
              />
            </Modal>

            {/* properties modal */}
            <Modal
              open={isPropertyDialogOpen}
              /* istanbul ignore next */
              onClose={() => setIsPropertyDialogOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <AssignEntryTableContainer
                subjectEntity={zoneSchedules}
                tableChild={
                  <BookingPropertyAssignTable
                    subjectEntity={zoneSchedules}
                    entries={floorPlan.bookingProperties}
                    onAssign={bookProp => onChangeBookProp(selectedIds, bookProp)}
                    onClose={() => setIsPropertyDialogOpen(false)}
                  />
                }
                type={t("Properties")}
                onClose={() => setIsPropertyDialogOpen(false)}
              />
            </Modal>

            {/* access control modal */}
            <Modal
              open={isAccessControlOpen}
              /* istanbul ignore next */
              onClose={() => setIsAccessControlOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <ZoneAccessControlDialogContent
                  selectedZone={zoneSchedules[0]}
                  onClose={() => setIsAccessControlOpen(false)}
                />
              </>
            </Modal>

            {/** print QR Code modal for conference zone */}
            <QrCodeComponent
              open={isQrModalOpen}
              floorPlanName={floorPlanName}
              entries={zoneSchedules}
              onClose={() => setIsQrModalOpen(false)}
            />
          </InfoBox>
        )}
      </InfoSideBar>
    );
  }
);
