import { useTranslation } from "react-i18next";
import { getOrgBackend } from "../../../app/backend";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";

export function useRemoteUpdateStoredScheduleColleagues() {
  const { t } = useTranslation();
  return useRemoteMutation<void, RemoteApiError, { userIds: string[] }>({
    url: `${getOrgBackend()}/stored-users-in-booking-calendar`,
    key: ["stored-users-in booking-calendar"],
    method: "post",
    snackbarTextOnSuccess: t(`Successfully stored colleagues`),
    snackbarTextOnFail: t(`Failed to store colleagues`)
  });
}
