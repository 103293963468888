import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack
} from "@mui/material";
import { Datepicker, locale } from "@mobiscroll/react";

import { RFi } from "../../../../features/Reports/typings/reports.types";
import { useTranslation } from "react-i18next";
import { LocationInventory } from "../../../../features/FloorManager/typings/location-inventory";
import { Dispatch, SetStateAction } from "react";
import useTimeFormat from "../../../../hooks/useTimeFormat/useTimeFormat";
import { DateTime } from "luxon";

interface Props {
  financeReport: RFi;
  setFinanceReport: Dispatch<SetStateAction<RFi>>;
}

const renderOptionsLocation = (data: LocationInventory[]) => {
  return data.map((dt: LocationInventory) => {
    return (
      <MenuItem value={dt.id} key={dt.id}>
        {dt.name}
      </MenuItem>
    );
  });
};

export function FinanceReportTopBar({ financeReport, setFinanceReport }: Props) {
  const { t, i18n } = useTranslation();
  const { dateFormat } = useTimeFormat();

  return (
    <Paper>
      <Stack
        direction={{
          md: "row",
          sm: "column"
        }}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "nowrap",
          p: 2,
          gap: 2
        }}
      >
        <Box>
          {financeReport.initialLocations && (
            <>
              <FormControl>
                <InputLabel id={"location-select-label"}>{t("Location")}</InputLabel>
                <Select
                  labelId={"location-select-label"}
                  label={t("Location")}
                  disabled={!financeReport.initialLocations.length}
                  sx={{
                    minWidth: 150,
                    maxWidth: 200
                  }}
                  value={financeReport.selectedInitialLocation || ""}
                  onChange={(event: SelectChangeEvent) => {
                    const value = +event.target.value;
                    const selectedLocation = financeReport.initialLocations.filter(
                      location => location.id === value
                    )[0];
                    setFinanceReport(prevState => ({
                      ...prevState,
                      selectedLocation,
                      selectedInitialLocation: event.target.value as string
                    }));
                  }}
                >
                  {renderOptionsLocation(financeReport.initialLocations)}
                </Select>
              </FormControl>
            </>
          )}
        </Box>
        {financeReport.initialBookingFrom && (
          <>
            <Datepicker
              data-testid={"dp-start"}
              inputStyle={"outline"}
              label={t("Start Date")}
              format={dateFormat}
              locale={locale[i18n.language]}
              min={financeReport.initialBookingFrom}
              max={financeReport.initialBookingTo || undefined}
              onChange={ev => {
                const date = DateTime.fromJSDate(ev.value as Date);
                setFinanceReport(prevState => ({
                  ...prevState,
                  bookingFromFinance: date
                }));
              }}
              value={financeReport.bookingFromFinance}
            />
            <Datepicker
              data-testid={"dp-end"}
              inputStyle={"outline"}
              label={t("End Date")}
              format={dateFormat}
              locale={locale[i18n.language]}
              min={financeReport.initialBookingFrom}
              max={financeReport.initialBookingTo || undefined}
              onChange={ev => {
                const date = DateTime.fromJSDate(ev.value as Date);
                setFinanceReport(prevState => ({
                  ...prevState,
                  bookingToFinance: date
                }));
              }}
              value={financeReport.bookingToFinance}
            />
          </>
        )}
      </Stack>
    </Paper>
  );
}
