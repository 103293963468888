import { useEffect, useMemo, useState } from "react";
import {
  MRT_ColumnDef,
  MRT_TableOptions,
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { EditableCategoryListToolbar } from "../Category/editable-category-list-toolbar.component";
import { EditableCategoryListRowActions } from "../Category/partial/editable-category-row-actions.partial";
import { commonLocalization } from "../../functions/tableLocalization";
import TableDeleteConfirmDialog from "../Common/TableDeletionConfirmDialog/TableDeletionConfirmDialog.component";
import { CategoryTableSlideoutPanel } from "../Category/editable-category-list.partial";
import { useRemoteFetchBookingProperty } from "../../hooks/Remote/BookingProperty/useRemoteFetchBookingProperty";
import { useRemoteUpdateBookingProperty } from "../../hooks/Remote/BookingProperty/useRemoteUpdateBookingProperty";
import { useRemoteCreateBookingProperty } from "../../hooks/Remote/BookingProperty/useRemoteCreateBookingProperty";
import { useRemoteDeleteBookingProperty } from "../../hooks/Remote/BookingProperty/useRemoteDeleteBookingProperty";
import { BookingProperty } from "../FacilityManager/Domain/Types/FloorPlan/BookingProperty.type";
import { validateBookingPropertyRow } from "./editable-booking-property-list.functions";
import { mapNewRowToPartialBookingProperty } from "./helpers/mapNewRowToPartialBookingProperty";
import { mapUpdatedRowPartialBookingProperty } from "./helpers/mapUpdateRowToPartialBookingProperty";

export default function EditableBookingPropertyList() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<BookingProperty[]>([]);
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletionSelected, setDeletionSelected] = useState<number[] | null>(null);

  const {
    data: remoteBookingProperty,
    refetch: refetchRemoteBookingProperty,
    isLoading: isBookingPropertyLoading,
    isFetching: isBookingPropertyFetching
  } = useRemoteFetchBookingProperty();

  const { mutateAsync: createBookingProperty, status: createBookingPropertyStatus } =
    useRemoteCreateBookingProperty();
  const { mutateAsync: removeBookingProperty, status: removeBookingPropertyStatus } =
    useRemoteDeleteBookingProperty();
  const { mutateAsync: updateBookingProperty, status: updateBookingPropertyStatus } =
    useRemoteUpdateBookingProperty();

  /** delete an equipment */
  async function handleRowDelete(rowIds: number[]) {
    removeBookingProperty({ ids: rowIds });
  }

  /** adds an equipment */
  const handleRowAdd: MRT_TableOptions<BookingProperty>["onCreatingRowSave"] = async ({
    values,
    table
  }) => {
    const newValidationErrors = validateBookingPropertyRow(values);

    if (Object.values(newValidationErrors).some(error => error)) {
      return setValidationErrors(newValidationErrors);
    }

    setValidationErrors({});

    const newVal = mapNewRowToPartialBookingProperty(values);
    await createBookingProperty(newVal);
    table.setCreatingRow(null); //exit creating mode
  };

  /** updates an equipment */
  const handleRowUpdate: MRT_TableOptions<BookingProperty>["onEditingRowSave"] = async ({
    row,
    values,
    table
  }) => {
    const newValidationErrors = validateBookingPropertyRow(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      return setValidationErrors(newValidationErrors);
    }

    setValidationErrors({});

    const newVal = mapUpdatedRowPartialBookingProperty(values, row.original.id);
    await updateBookingProperty(newVal);
    table.setEditingRow(null); //exit editing mode
  };

  const column = useMemo<MRT_ColumnDef<BookingProperty>[]>(
    () => [
      {
        accessorKey: "name",
        header: t("Name"),
        size: 350,
        enableEditing: true,
        muiEditTextFieldProps: {
          onChange: e => {
            if (e.currentTarget.value === "") {
              setValidationErrors({
                ...validationErrors,
                name: undefined
              });
            }
          },
          onFocus: () =>
            /* istanbul ignore next */
            setValidationErrors({
              ...validationErrors,
              name: undefined
            }),
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name
        }
      },
      {
        accessorKey: "description",
        header: t("Description"),
        muiEditTextFieldProps: {
          onChange: e => {
            if (e.currentTarget.value === "") {
              setValidationErrors({
                ...validationErrors,
                description: undefined
              });
            }
          },
          onFocus: () =>
            /* istanbul ignore next */
            setValidationErrors({
              ...validationErrors,
              description: undefined
            }),
          required: true,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description
        },
        size: 600,
        enableEditing: true,
        filterFn: "includesString"
      }
    ],
    [validationErrors, remoteBookingProperty, i18n.language]
  );

  const table = useMaterialReactTable({
    columns: column ?? [],
    data: data ?? [],
    getRowId: row => row.id?.toString(),
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleRowAdd,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleRowUpdate,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enableRowSelection: true,
    enableEditing: true,
    positionToolbarAlertBanner: "none",
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: { pageSize: 10, pageIndex: 0 }
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20, 30],
      variant: "outlined"
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined"
    },
    state: {
      isLoading: isBookingPropertyLoading,
      isSaving:
        (createBookingPropertyStatus ||
          updateBookingPropertyStatus ||
          removeBookingPropertyStatus) === "loading",
      showProgressBars: isBookingPropertyFetching
    },
    paginationDisplayMode: "pages",
    localization: {
      ...commonLocalization(t),
      noRecordsToDisplay: t("_editable_list_noRecords", { item: t("_noBookingProperty") })
    },
    renderTopToolbar: ({ table }) => {
      return (
        <EditableCategoryListToolbar
          table={table}
          usedHeadlineKey={t("Booking Property")}
          disabledDeleteButton={table.getSelectedRowModel().flatRows.length <= 0}
          onDelete={() => /* istanbul ignore next */ {
            setIsDeleteModalOpen(true);
            const selected = table.getSelectedRowModel().rows.map(row => row.original.id);
            setDeletionSelected(selected);
          }}
          addItemLabel={"Add Property"}
        />
      );
    },
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <CategoryTableSlideoutPanel
        table={table}
        row={row}
        internalEditComponents={internalEditComponents}
        categoryType={"booking-property"}
        headline={t("_editable_categoryList_Add", { item: t("Booking Property") })}
      />
    ),
    renderRowActions: ({ row, table }) => {
      return (
        <EditableCategoryListRowActions
          row={row}
          table={table}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setDeletionSelected={setDeletionSelected}
        />
      );
    },
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <CategoryTableSlideoutPanel
        table={table}
        row={row}
        internalEditComponents={internalEditComponents}
        categoryType={"booking-property"}
        headline={t("_editable_categoryList_Edit", { item: t("Booking Property") })}
      />
    )
  });

  useEffect(() => {
    if (!remoteBookingProperty) return;
    setData(
      remoteBookingProperty.map(el => ({
        id: el.id,
        name: el.name,
        description: el.description
      }))
    );
  }, [remoteBookingProperty]);

  // after deleting a row, refresh the list
  useEffect(() => {
    if (removeBookingPropertyStatus === "success") {
      const title =
        table.getSelectedRowModel().rows.length > 1 ? "booking property" : "booking properties";

      enqueueSnackbar(t(`Successfully deleted ${title}`), { variant: "success" });
      table.resetRowSelection(); // exit selecting mode
      setIsDeleteModalOpen(false);
      refetchRemoteBookingProperty();
      setDeletionSelected(null);
    }
  }, [removeBookingPropertyStatus]);

  // after adding a row, refresh the list
  useEffect(() => {
    if (createBookingPropertyStatus === "success") refetchRemoteBookingProperty();
  }, [createBookingPropertyStatus]);

  // after updating a eow, refresh the list
  useEffect(() => {
    if (updateBookingPropertyStatus === "success") refetchRemoteBookingProperty();
  }, [updateBookingPropertyStatus]);

  return (
    <>
      <MaterialReactTable table={table} />

      {/* Confirm Deletion Modal */}
      <TableDeleteConfirmDialog
        dialogDesc={
          deletionSelected?.length === 1
            ? t("_tableSingleDeletionConfirmationDesc", { title: t("Booking Property") })
            : t("_tableDeletionConfirmationDesc")
        }
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        onConfirm={() => {
          /* istanbul ignore next */
          if (deletionSelected?.length) handleRowDelete(deletionSelected);
        }}
        deleteStatus={removeBookingPropertyStatus}
      />
    </>
  );
}
