import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Datepicker, locale } from "@mobiscroll/react";
import { DateTime } from "luxon";
import React, { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import useTimeFormat from "../../../hooks/useTimeFormat/useTimeFormat";
import { DateRange } from "../../Reports/SupervisorReport/supervisor-report.component";

export interface DatePickerDialogProps {
  dates: { start: string; end: string };
  onChangeDateRange: (range: DateRange) => void;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

export const DatePickerDialog: React.FC<DatePickerDialogProps> = ({
  setOpen,
  dates,
  onChangeDateRange,
  open
}) => {
  const { t, i18n } = useTranslation();
  const { dateFormat } = useTimeFormat();

  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t("Change timeframe")}</DialogTitle>

      <Datepicker
        label={t("Start")}
        max={dates.end}
        value={dates.start}
        onChange={ev =>
          onChangeDateRange({
            start: ev.value ? (DateTime.fromJSDate(ev.value as Date).toISO() as string) : "",
            end: dates.end
          })
        }
        dateFormat={dateFormat.toUpperCase()}
        locale={locale[i18n.language]}
      />
      <Datepicker
        label={t("End")}
        min={dates.start}
        value={dates.end}
        onChange={ev =>
          onChangeDateRange({
            start: dates.start,
            end: ev.value ? (DateTime.fromJSDate(ev.value as Date).toISO() as string) : ""
          })
        }
        dateFormat={dateFormat.toUpperCase()}
        locale={locale[i18n.language]}
      />
    </Dialog>
  );
};
