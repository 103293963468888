import { Assistant, PinDrop } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocationAvailability } from "../../../features/Booking-Form/typings/booking-location";

type P = {
  location: LocationAvailability;
  isAutomatedSeatingAvailable: boolean | undefined;
  handleGoogleLocationClick(address: string): void;
  handleAutomatedSeatingClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
};

export function LocationCardMediaChildren({
  location,
  isAutomatedSeatingAvailable,
  handleGoogleLocationClick,
  handleAutomatedSeatingClick
}: P) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { name, address1, address2, postalCode, city } = location;

  const isMobileWorking = name === "Mobile Working";

  const handleGoogleLocClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    const addressFind = `${address1} ${address2} ${postalCode} ${city}`;
    handleGoogleLocationClick(addressFind);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 0.5,
          minWidth: 160,
          "@media (max-device-width: 400px)": { minWidth: 130 },
          position: "relative" // Important for layering
        }}
        data-testid="location-card-media-chilren-parent-box"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            bgcolor: theme.palette.background.paper,
            position: "absolute", // Position it under the child Box
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            opacity: 0.8
          }}
        />

        {!isMobileWorking && (
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            {/* google map link */}
            <Tooltip
              title={<Typography>{t("_googleMapLocDesc")}</Typography>}
              aria-label={"_googleMapLocDesc"}
            >
              <IconButton
                sx={{ opacity: 1 }}
                data-testid="google-map-loc-btn"
                onClick={handleGoogleLocClick}
              >
                <PinDrop />
              </IconButton>
            </Tooltip>

            {/* select automatic seating */}
            {isAutomatedSeatingAvailable && (
              <Tooltip
                title={<Typography>{t("_freeWorkplaceChosen")}</Typography>}
                aria-label={"_freeWorkplaceChosen"}
              >
                <IconButton
                  sx={{ opacity: 1 }}
                  data-testid="automated-seating-loc-btn"
                  onClick={handleAutomatedSeatingClick}
                >
                  <Assistant />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}
